import React, {useState, useEffect} from "react";
import * as Yup from 'yup';
import {Formik} from 'formik';
import Swal from 'sweetalert2'
import { ProfileFormComponent, Tabs } from "./";
const Profile = (props) => {
  const {api, langs} = props;
  const [config, setConfig] = useState({
    initialValues: {
      Name: '',
      Lastname: '',
      Email: '',
      Phone: '',
      Password: '',
      PasswordConfirm: '',
    },
    validationSchema: Yup.object().shape({
      Name: Yup.string().required('Name is Required'),
      Lastname: Yup.string().required('Lastname is Required'),
      Email: Yup.string().email('Invalid email').required('Email is Required'),
      Phone: Yup.string().required('Phone is Required'),
      PasswordConfirm: Yup.string().oneOf([Yup.ref('Password'), null], 'Passwords must match'),
    }),
    enableReinitialize: true
  })

  const handleSubmit = async (values) => {
    let apiData = await api.updateProfile(values);
    Swal.fire({
      icon: !apiData.statusCode ? 'error' : 'success',
      title: !apiData.statusCode ? langs.errorTitle : langs.successTitle,
      text: !apiData.statusCode ? langs.errorText : langs.successText,
    })
    if (apiData.statusCode) {
      fetchData()
    }
  }

  const fetchData = async () => {
    let apiData = await api.getProfile();
    if (apiData.statusCode && apiData.data) {
      let newConfig = {...config}
      newConfig.initialValues.Name = apiData.data.name;
      newConfig.initialValues.Lastname = apiData.data.lastname;
      newConfig.initialValues.Email = apiData.data.email;
      newConfig.initialValues.Phone = apiData.data.phone;
      newConfig.initialValues.Password = '';
      newConfig.initialValues.PasswordConfirm = '';
      setConfig(newConfig)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])


  return (
    <div className="container position-relative">
      <Tabs {...props} />
      <Formik {...config} onSubmit={handleSubmit} component={
        (formikProps) => <ProfileFormComponent {...formikProps} {...{langs}} />
      } />
    </div>
  )
}

export default Profile