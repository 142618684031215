const CONFIG = {
	HOST: {
		APP_URL : 'https://levelupedu.ge',
		API_URL : 'https://levelupedu.ge/api/',
		// APP_URL : 'http://localhost:3000',
		// API_URL : 'http://levelup.lo/api/',
		BRANCH : 'LOCAL'
	},
	COMMON: {
		DS 						 	 	 : '/',
	},
	LANGS: {
		DEFINITIONS: {
			ka : 4,
			en : 1
		},
		DEFAULT_LANG: 'ka',
		LIST: [4, 1],
		VIEW: ['ka', 'en']
	},
	API: {
		GET_LANGS 				: 'global/langs',
		GET_COURSES 			: 'courses',
		GET_ABOUT 				: 'about',
		GET_TERMS 				: 'terms',
		GET_CONTACT 			: 'contact',
		USER: {
			LOGIN 				: 'user/login',
			REGISTER 			: 'user/register',
			GET_PROFILE 		: 'user/profile',
			EDIT_PROFILE 		: 'user/profile/update',
			GET_ORDERS 			: 'user/orders',
			CREATE_ORDER 		: 'user/orders/create',
			EDIT_ORDER 			: 'user/orders/update',
			LOGOUT 					: 'user/logout',
		},
		ADMIN: {
			LOGIN 					: 'admin/login',
			UPLOAD 					: 'admin/upload',
			EDIT_COURSES				: 'admin/courses/update',
			ADD_COURSES				: 'admin/courses/save',
			DELETE_COURSES 			: 'admin/courses/delete',
			GET_ABOUT 				: 'admin/about',
			EDIT_ABOUT				: 'admin/about/update',
			GET_TERMS 				: 'admin/terms',
			EDIT_TERMS				: 'admin/terms/update',
			EDIT_MAIN				: 'admin/main/update',
			GET_CONTACT 			: 'admin/contact',
			EDIT_CONTACT			: 'admin/contact/update',
			GET_LANGS				: 'admin/langs/list',
			EDIT_LANGS				: 'admin/langs/update',
			GET_REGISTRATION 		: 'admin/registration',
			EDIT_REGISTRATION		: 'admin/registration/update',
			ADD_REGISTRATION		: 'admin/registration/save',
			DELETE_REGISTRATION 	: 'admin/registration/delete',
			GET_ORDERS 				: 'admin/orders',
		}
	},
	STORAGE: {
		LANGUAGE: 'Language',
		AUTH: 'Auth',
		USER: 'User',
	}
};

export default CONFIG;

