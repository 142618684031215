import React, {useState, useEffect, useContext} from 'react'
import Swal from 'sweetalert2'

import { API } from '../../../global'

import Login from './Login'
import Home from './Home'
import Courses from './Courses'
import About from './About'
import Contact from './Contact'
import Langs from './Langs'
import Registration from './Registration'
import Orders from './Orders'
import Terms from './Terms'

import { AppDataContext } from 'contexts/app';
import { HistoryContext } from 'contexts/history';
import { UserContext } from 'contexts/user';

const Admin = () => {
    const api               = new API()

    const [active, setActive]                                           = useState(false)
    const {langs, getLang } 						                    = useContext(AppDataContext);
    const { login, checkIfAuthorized, logout } 		            = useContext(UserContext);
    const { changePage, replace, stringify, params, push, assignParam }	= useContext(HistoryContext);

    const nav = [
        { Page: 'login', Component: Login },
        { Page: 'home', Component: Home },
        { Page: 'courses', Component: Courses },
        { Page: 'about', Component: About },
        { Page: 'contact', Component: Contact },
        { Page: 'registration', Component: Registration },
        { Page: 'orders', Component: Orders },
        { Page: 'langs', Component: Langs },
        { Page: 'terms', Component: Terms },
    ]

    const initial = () => {
        setActive(nav.find(o => o.Page === api.routeParams.page))
    }

    useEffect(initial, [api.routeParams])

    const onAction = (statusCode, link) => {
        Swal.fire({
            icon: !statusCode ? 'error' : 'success',
            title: !statusCode ? langs.adminErrorTitle : langs.adminSuccessTitle,
            text: !statusCode ? langs.adminErrorText : langs.adminSuccessText,
        })
        if (link) {
            push(`/${getLang()}/admin/${link}`)
        }
    }

    const onDelete = (conf) => {
        Swal.fire({
            title: langs.adminDeleteQuestion,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: langs.adminYes,
            cancelButtonText: langs.adminNo
          }).then( async (result) => {
            if (result.isConfirmed) {
                let apiData = await conf.isConfirmed()
                onAction(apiData.statusCode)
                if (apiData.statusCode) {
                    conf.isSuccess()
                }
            }
          })
    }

    return (
        <div className="container-admin mt-200px">
            {(active && checkIfAuthorized()) ?
                <active.Component {...{
                    api,
                    login,
                    logout,
                    getLang,
                    langs,
                    changePage,
                    replace,
                    stringify,
                    params,
                    push,
                    assignParam,
                    onAction,
                    onDelete
                }} />
            :
                <Login {...{
                    api,
                    login,
                    getLang,
                    langs,
                    changePage,
                    replace,
                    stringify,
                    params,
                    push
                }} />
            }
        </div>
    )
};

export default Admin;
