import React from 'react';
import Paginate from 'react-paginate';

const Pagination = (params) => {
	if (params.pageCount <= 1) {
		return false;
	}
	const defaultParams = {
		containerClassName: 'simple-pagination',
		pageClassName: 'pagination-item',
		pageLinkClassName: 'pagination-link',
		breakClassName: 'pagination-item',
		activeLinkClassName: 'active',
		previousClassName: 'pagination-item',
		nextClassName: 'pagination-item',
		previousLinkClassName: 'pagination-link pagination-arrow pagination-prev',
		nextLinkClassName: 'pagination-link pagination-arrow pagination-next',
		previousLabel: '',
		nextLabel: '',
		breakLinkClassName: 'pagination-link pagination-break',
	}
	return (
		<Paginate {...defaultParams} {...params}/>
	)
}

export default Pagination