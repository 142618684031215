import React, { useEffect, useState } from "react";
import { Tabs } from "./";
import moment from "moment";
const Orders = (props) => {
  const { langs, lang, api, stringify, replace, push } = props;
  const [orders, setOrders] = useState([])
  const statusList = {
    0: langs.orderInProgress,
    1: langs.orderSuccess,
    2: langs.orderFail
  }

  const goToOrder = (order) => {
    if (order.status === 0) {
      push(`/${lang}/user/orders/${order.id}`, {}, true)
    }
    return;
  }

  const getOrders = async () => {
    let apiData = await api.getOrders(stringify({
      limit: 50
    }, true));
    if (apiData.statusCode && apiData.data) {
      setOrders(apiData.data.data)
    }
  }

  useEffect(() => {
    getOrders()
  }, [])

  useEffect(() => {
    if (api.routeParams.id) {
      const check = async () => {
        let apiData = await api.getOrder(api.routeParams.id);
        if (apiData.statusCode) {
          getOrders();
          replace(`/${lang}/user/orders`, {})
        } else {
          replace(`/${lang}/user/orders`, {})
        }
      }
      check()
    }
  }, [api.routeParams.id])
  return (
    <div className="container position-relative">
      <Tabs {...props} />
      <div className="row">
        {orders.map((item, index) => {
          return (
            <div key={index} className="col-12 col-md-4 col-lg-3 my-4 ">
              <div className={`bg-main-blue p-16px ${item.status === 0 ? 'cursor-pointer' : ''}`} onClick={(e) => goToOrder(item)}>
                <div className=" font-size-18 text-white text-center font-base-caps">
                  {item.course_id}
                </div>
                <div className="font-size-18 text-white text-center font-base-caps">
                  {item.course}
                </div>
                <div className=" font-size-18 text-white text-center font-base-caps">
                  {moment(new Date(item.created_at)).format('YYYY-MM-DD').toString()}
                </div>
                <div className={`font-size-18  text-center font-base-caps ${item.status === 1 ? 'text-success' : 'text-danger' }`}>
                  {statusList[item.status]}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Orders