import React, { useState, useContext, useEffect } from 'react';

import { API } from '../../../global'
import { AppDataContext } from 'contexts/app';
import { HistoryContext } from 'contexts/history';
import * as Yup from 'yup';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import Swal from 'sweetalert2'

const Registration = () => {
    const api = new API();
    const { params } =  useContext(HistoryContext)
    const { langs, lang } =  useContext(AppDataContext);
    const [course, setCourse] = useState(null);
    const [submitState, setSubmitState] = useState(false)

    const getInitialValues = () => ({
        Name: '',
        Lastname: '',
        Email: '',
        Phone: '',
        // IDNumber: '',
        // Address: '',
        Course: params.course ?? 'basic',
        Package: params.package ?? '',
        Terms: '',
        Status: 1,
    })

    const [data, setData] = useState(getInitialValues());

    const getValidationSchema = () => {
        let validationSchema = {
            Name: Yup.string().required(langs.errorName),
            Lastname: Yup.string().required(langs.errorName),
            Email: Yup.string().email('Invalid email format').required(langs.errorName),
            Phone: Yup.string().min(5, 'Min Required').max(12, 'Max Required').required(langs.errorName),
            // IDNumber: Yup.string().min(5, 'Min Required').max(12, 'Max Required').required(langs.errorName),
            // Address: Yup.string().required(langs.errorName),
            Course: Yup.string().required(langs.errorName),
            Terms: Yup.string().required(langs.errorName),
        }
        return Yup.object().shape(validationSchema)
    }

    const config = {
        initialValues: data,
        validationSchema: getValidationSchema(),
        enableReinitialize: true
    }

    const handleSubmit = async (values, { resetForm }) => {
        setSubmitState(true)
        const sendData = await api.addRegistration(values);
        Swal.fire({
            icon: !sendData.statusCode ? 'error' : 'success',
            title: !sendData.statusCode ? langs.adminErrorTitle : langs.adminSuccessTitle,
            text: !sendData.statusCode ? langs.adminErrorText : langs.adminSuccessText,
        })
        setSubmitState(false)
        resetForm();
    }
    
    const fetchData = async () => {
        let apiData = await api.getCourses(params.package);
        if (apiData.statusCode) {
            setCourse(apiData.data);
        }
    };

    const initial = () => {
        setCourse(false)
        if (params.package && !course) {
            fetchData();
        }
    }

    useEffect(initial, [params]);

    return (
    <div className="container pt-24px pb-100px mt-64px">
        <Formik {...config} onSubmit={handleSubmit}>
        {({ values }) => (
            <Form autoComplete="off">
                <div className="form-box p-16px p-md-36px w-100 max-width-750px border-radius-md">
                    <div className="border-bottom pb-12px mb-16px ">
                        <div className='font-size-24 font-base text-white'>
                            {course ? course[`title_${lang}`] : ''}
                        </div>
                        <div className='font-size-21 font-base text-white'>
                            {course ? course[`short_description_${lang}`] : ''}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className='font-size-24  font-base  text-white '>
                            {langs.localRegister}
                            </div>
                        </div>
                    </div>
                    <div className="row my-16px">
                        <div className="col-12 col-md-3">
                            <div className="custom-control custom-radio  custom-control-inline">
                                <Field type="radio" id="course-1" value="basic" name="Course" className="custom-control-input" />
                                <label className="custom-control-label  text-white font-base font-size-16" htmlFor="course-1">
                                    {langs.localBasic}
                                </label>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 mb-12px mb-md-0">
                            <div className="custom-control  custom-radio   custom-control-inline">
                                <Field type="radio" id="course-2" value="pro" name="Course" className="custom-control-input" />
                                <label className="custom-control-label  text-white font-base font-size-16" htmlFor="course-2">
                                {langs.localPro}
                                </label>
                                
                            </div>
                        </div>
                        <ErrorMessage name="Course" component="label" className="error text-main-title"/>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 ">
                            <div className="form-group  mb-8px ">
                                <label htmlFor="Name" className="text-main-title font-medium font-size-18  text-capitalize">
                                {langs.localName}
                                </label>
                                <Field type="text" className="form-control" name="Name" id="Name" />
                                <ErrorMessage name="Name" component="label" className="error text-main-title"/>
                            </div>
                        </div>
                        <div className="col-12 col-md-6  ">
                            <div className="form-group  mb-8px ">
                                <label htmlFor="Lastname" className="text-main-title font-medium font-size-18  text-capitalize">
                                {langs.localLastName}
                                </label>
                                <Field type="text" className="form-control" name="Lastname" id="Lastname" />
                                <ErrorMessage name="Lastname" component="label" className="error text-main-title"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6  ">
                            <div className="form-group  mb-8px ">
                                <label htmlFor="Email" className="text-main-title font-medium font-size-18  text-capitalize">
                                {langs.localEmail}
                                </label>
                                <Field type="text" className="form-control" name="Email" id="Email" />
                                <ErrorMessage name="Email" component="label" className="error text-main-title"/>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 ">
                            <div className="form-group  mb-8px ">
                                <label htmlFor="Phone" className="text-main-title font-medium font-size-18  text-capitalize">
                                {langs.localPhone}
                                </label>
                                <Field type="number" className="form-control" name="Phone" id="Phone" />
                                <ErrorMessage name="Phone" component="label" className="error text-main-title"/>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-12 col-md-6 ">
                            <div className="form-group  mb-8px ">
                                <label htmlFor="IDNumber" className="text-main-title font-medium font-size-18  text-capitalize">
                                {langs.localIdNumber}
                                </label>
                                <Field type="number" className="form-control" name="IDNumber" id="IDNumber" />
                                <ErrorMessage name="IDNumber" component="label" className="error text-main-title"/>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 ">
                            <div className="form-group  mb-8px ">
                                <label htmlFor="Address" className="text-main-title font-medium font-size-18  text-capitalize  text-capitalize">
                                {langs.localAddress}
                                </label>
                                <Field type="text" className="form-control" name="Address" id="Address" />
                                <ErrorMessage name="Address" component="label" className="error text-main-title"/>
                            </div>
                        </div>
                    </div> */}
                    <div className="row justify-content-center  mt-12px">
                        <div className="custom-control  custom-checkbox    custom-control-inline">
                            <Field type="checkbox" id="terms" name="Terms" className="custom-control-input" />
                            <label className="custom-control-label text-white font-base font-size-14" htmlFor="terms">
                            {langs.localAgree}
                            </label>
                        </div>
                    </div>
                    <ErrorMessage name="Terms" component="label" className="error  text-main-title w-100 text-center"/>
                    <div className="row justify-content-center  mt-16px">
                        <button type="submit" disabled={submitState} 
                            className='font-size-18 btn bg-main-title text-white border-radius-md font-base-caps mx-auto'>
                            {langs.localRegistration}
                        </button>
                    </div>
                </div>
            </Form>
        )}
        </Formik>
    </div>
    )
};

export default Registration;