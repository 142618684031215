import React, { useState, useEffect, useRef } from 'react'
import { API } from '../../../global'
import Slider from 'global/components/Slider';
import { Loading, CoursesCardLarge } from '../../../global/components';
import insta from '../../../resources/img/icons/insta.svg'
import fb from '../../../resources/img/icons/fb.svg'
const View = () => {
    const api = new API();
    const [data, setData] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            let apiData = await api.getCourses();
            if (apiData.statusCode) {
                let groupedData = [] 
                apiData.data.list.data.map((item, i) => {
                    if (!groupedData[item.group_id]) {
                        groupedData[item.group_id] = [item]
                    } else {
                        groupedData[item.group_id].push(item)
                    }
                    return item;
                })
                setData(groupedData);
            }
        };
        fetchData()
    }, []);

    const sliderRef = useRef(null);
    const settings = {
        containerClass: 'swiper-container  w-100 h-100',
        spaceBetween: 0,
        speed: 1000,
        slidesPerView: 1,
        loop: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
    };


    return data ?
        <div className="home ">
            {(data && data.length > 0) &&
                <div className="">
                    <div className="main-section w-100">
                        <Slider {...{ settings }} ref={sliderRef} >
                            {data.map((item, i) => (
                                <div className="slide-box position-relative w-100" key={i}>
                                    <CoursesCardLarge {...{
                                        data: item,
                                        page: 'courses'
                                    }} />
                                </div>
                            ))}
                        </Slider>
                        <div className="site-info px-20px px-lg-116px ">
                            <div className='text-white font-base font-size-28'>
                                {/* levelupedu.ge */}
                            </div>
                            <div className="social d-flex ">
                                <a href='https://www.instagram.com/levelupedu.ge/' target="_blank">
                                    <div className="insta w-25px h-25px mr-16px ">
                                        <img src={insta} alt="" className='w-100 h-100 opacity-50' />
                                    </div>
                                </a>
                                <a href='https://www.facebook.com/levelupeduge/' target="_blank" rel="noopener noreferrer">
                                    <div className="fb w-25px h-25px">
                                        <img src={fb} alt="" className='w-100 h-100 opacity-50' />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
        :
        <Loading />
};

export default View;
