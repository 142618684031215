
import React, { useContext } from 'react'
import { AppDataContext } from 'contexts/app';
import { Loading } from '../../../global/components'


import logo from '../../../resources/img/icons/logo.svg'
import fb from '../../../resources/img/icons/fb.svg'
import isnta from '../../../resources/img/icons/insta.svg'
import linkdin from '../../../resources/img/icons/link.svg'
import whatsap from '../../../resources/img/icons/whatsa.svg'
const View = () => {
    const { getLang, langs, changeLanguage } = useContext(AppDataContext);

    return (
        <>
            <div className="container mt-64px position-relative">
                <div className="my-24px font-size-28 text-white text-center font-base-caps">{langs.localContact}</div>
                <div className="my-12px font-size-21 font-size-md-28 text-white text-center">
                    <a href="mailto:levelupschooledu@gmail.com" className='font-size-21 font-size-md-28 text-white text-center'>{langs.contactMail}</a>
                   </div>
                <div className="d-flex justify-content-center">
                    <div className="info-box d-flex flex-column align-items-start mt-24px ">
                        <a href="tel:571121212">
                            <div className="info-item d-flex  align-items-center mb-12px">
                                <div className="icon w-22px h-22px d-flex align-items-center bg-white rounded-circle">
                                    <img src={whatsap} alt="" className='w-100 h-100 object-fit-cover' />
                                </div>
                                <div className='font-size-21 text-white ml-8px'>{langs.localphoneNumber}</div>
                            </div>
                        </a>
                        <a href="https://www.facebook.com/Level-Up-%E1%83%9A%E1%83%94%E1%83%95%E1%83%94%E1%83%9A-%E1%83%90%E1%83%A4-102675078839476" target="_blank">
                            <div className="info-item d-flex align-items-center mb-12px">
                                <div className="icon w-22px h-22px d-flex align-items-center">
                                    <img src={fb} alt="" className='w-100 h-100 object-fit-cover' />
                                </div>
                                <div className='font-size-21 text-white ml-8px'>{langs.contactAbout}</div>
                            </div>
                        </a>
                        <a href="https://www.instagram.com/levelupedu.ge/" target='_blank'>
                        <div className="info-item d-flex align-items-center mb-12px">
                            <div className="icon w-22px h-22px d-flex align-items-center rounded-circle overflow-hidden bg-main-title">
                                <img src={isnta} alt="" className='w-100 h-100 object-fit-cover' />
                            </div>
                            <div className='font-size-21 text-white ml-8px'>{langs.contactSiteName}</div>
                        </div>
                        </a>
                        <a href="https://www.linkedin.com/in/level-up-school-02a23922b/" target="_blank">
                        <div className="info-item d-flex align-items-center mb-12px">
                            <div className="icon w-22px h-22px d-flex align-items-center">
                                <img src={linkdin} alt="" className='w-100 h-100 object-fit-cover' />
                            </div>
                            <div className='font-size-21 text-white ml-8px'>{langs.localSchool}</div>
                        </div>
                        </a>
                  
                    </div>
                </div>

            </div>
            <div className="logo-bottom">
                <img src={logo} alt="" />
            </div>
        </>
    )
};

export default View;
