import React from "react";
import { Link } from 'react-router-dom';

const Tabs = (props) => {
  const {langs, getLang} = props;
  return (
    <div className="row mb-36px">
      <div className="col-6 col-md-2 font-size-18 text-left font-base-caps">
        <Link to={`/${getLang()}/user/orders/`} className="text-white">
          {langs.localOrders} 
        </Link>
      </div>
      <div className="col-6 col-md-2  font-size-18 text-left font-base-caps">
        <Link to={`/${getLang()}/user/profile/`} className="text-white">
          {langs.localProfile} 
        </Link>
      </div>
    </div>
  )
}

export default Tabs