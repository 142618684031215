import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'

import { Loading, Pagination } from '../../../global/components';
import { Sidebar } from '../components'

const Orders = (props) => {
    const [list, setList] = useState(false);
    const [paginate, setPaginate]                                   = useState(null);

    const statusList = {
        0: props.langs.orderInProgress,
        1: props.langs.orderSuccess,
        2: props.langs.orderFail
      }

    const fetchData = async () => {
        let apiData = await props.api.getAdminOrders(
            props.stringify({ Limit: 10, Page: props.params.Page ? props.params.Page : 1 })
        );
        if (apiData.statusCode) {
            setList(apiData.data.data);
            setPaginate({
                pageCount: apiData.data.pagination.totalPages,
                forcePage: props.params.Page !== null && props.params.Page > 0 ? props.params.Page - 1 : 0,
                onPageChange: (data) => {
                    props.assignParam({ Page: data.selected + 1 }, true)
                }
            });
        }
    };

    const showInfo = (item) => {
        Swal.fire({
            title: `ID${item.id}: ${item.name} ${item.lastname} ("${item.phone}")`,
            html:`Name: ${item.name}<br>Lastname: ${item.lastname}<br>Phone: ${item.phone}<br>ID Number: ${item.idnumber}<br>Email: ${item.email}<br><br>Address: ${item.idnumber}`
        })
    }

    const initial = () => {
        fetchData()
    }

    useEffect(initial, [props.params]);

    const ActiveComponent = (props) => {
        if (list) {
            return (
                <div className="border border-blue border-radius-md p-12px">
                    <div className="row mb-12px">
                        <div className="col">ID</div>
                        <div className="col">User</div>
                        <div className="col">Course</div>
                        <div className="col">Status</div>
                    </div>
                    {list.map((item, i) => (
                        <div key={i} className="row">
                            <div className="col">
                                {item.id}
                            </div>
                            <div className="col">
                                <div className="btn btn-primary mr-8px mb-8px font-size-18px" onClick={() => showInfo(item.user)}>
                                    {`${item.user.email}`}
                                </div>
                            </div>
                            <div className="col">
                                {item.course}
                            </div>
                            <div className="col">
                                {statusList[item.status]}
                            </div>
                        </div>
                    ))}
                    <div className="row d-flex justify-content-center mt-20px mt-sm-10px mt-lg-30px">
                        <Pagination {...paginate} />
                    </div>
                </div>
                
            )
        } else {
            return <Loading />
        }
    }

    return (
        <div className="row align-items-start">
            <Sidebar {...props} />
            <div className="col-m-9 admin-content">
                <ActiveComponent {...props} />
            </div>
        </div>
    )
};

export default Orders;
