import React, {useState, useEffect, useContext } from 'react'
import { AppDataContext } from 'contexts/app';
import { HistoryContext } from 'contexts/history';
import { UserContext } from 'contexts/user';
import { Link } from 'react-router-dom';
import { API } from '../../../global'
import logo from '../../../resources/img/icons/logo.svg'
import burger from '../../../resources/img/icons/burger.svg'
import close from '../../../resources/img/icons/close.svg'
const Header = (props) => {
    const { closeMenu, openMenu } = props
    const { getLang, langs, changeLanguage } = useContext(AppDataContext);
    const { push, history, getPagename, params } = useContext(HistoryContext);
    const { logout, checkIfAuthorized } = useContext(UserContext);
    const [cookieAgreement, setCookieAgreement] = useState(false);
    const api  = new API()

    const change = (newLang) => {
        changeLanguage(newLang)
        let location = history.location.pathname.split('/')
        location[1] = newLang
        push(`${location.join('/')}`)
    }
    const processCookieAgreement = () => {
		api.storage.set('CookieAgreement', JSON.stringify(1))
		setCookieAgreement(false)
	}
    useEffect(() => {
        if (!api.storage.exists('CookieAgreement')) {
            setCookieAgreement(true)
        }
    }, [])
    
    return (

        <>
            <header className={`py-26px px-20px px-lg-116px d-flex justify-content-between ${!getPagename() ? 'home' : ''}`}>
                <div className="btn-burger d-block d-md-none cursor-pointer" onClick={openMenu}>
                    <img src={burger} alt="" className='w-100 h-100' />
                </div>
                <div className="backdropburger" onClick={closeMenu}>

                </div>
                <Link to={`/${getLang()}`} className="font-bold-caps font-size-12 font-size-md-18 text-white w-100px w-md-180px ">
                    <img src={logo} alt="" />
                </Link>
                <ul className='header-menu-list d-flex align-items-center'>
                    <li className='mx-4px mx-md-12px '>
                        <Link to={`/${getLang()}`} className="font-bold-caps font-size-14 font-size-lg-18 text-white "
                            onClick={closeMenu}>
                            {langs.localHome}
                        </Link>
                    </li>
                    <li className='mx-4px mx-md-12px '>
                        <Link to={`/${getLang()}/courses`} className="font-bold-caps font-size-14 font-size-lg-18 text-white "
                            onClick={closeMenu}>
                            {langs.localCourses}
                        </Link>
                    </li>
                    <li className='mx-4px mx-md-12px '>
                        <Link to={`/${getLang()}/about`} className="font-bold-caps font-size-14 font-size-lg-18 text-white "
                            onClick={closeMenu}>
                            {langs.localAbout}
                        </Link>
                    </li>
                    <li className='mx-4px mx-md-12px '>
                        <Link to={`/${getLang()}/contact`} className="font-bold-caps font-size-14 font-size-lg-18 text-white "
                            onClick={closeMenu}>
                            {langs.localContact}
                        </Link>
                    </li>
                    <li className='mx-4px mx-md-12px '>
                        <Link to={`/${getLang()}/user${api.storage.exists('User') ? '/orders/' : ''}`}
                            className={`font-bold-caps font-size-14 font-size-lg-18 text-white  ${api.storage.exists('User') ? '' : 'bg-main-blue p-12px' }`}
                            onClick={closeMenu}>
                            {api.storage.exists('User') ? langs.localOrders : langs.localLogin}
                        </Link>
                    </li>
                    {checkIfAuthorized() &&
                        <li className='mx-4px mx-md-12px '>
                            <span className="font-bold-caps font-size-14 font-size-lg-18 text-white cursor-pointer bg-main-blue p-12px"
                                onClick={logout}>
                                {langs.adminLogOut}
                            </span>
                        </li>
                    }
                    <div className="d-flex align-items-center mx-4px mx-md-12px ">
                        <div className="lang font-bold-caps font-size-16 font-size-md-17px text-white cursor-pointer "
                            onClick={() => change(getLang() === 'ka' ? 'en' : 'ka')}>
                            {getLang() === 'ka' ? 'EN' : 'KA'}
                        </div>
                        <div className="btn-burger-close d-block d-md-none  cursor-pointer" onClick={closeMenu}>
                            <img src={close} alt="" />
                        </div>
                    </div>
                </ul>

            </header>

            {cookieAgreement &&
                <div className="cookie-box bg-white p-16px d-flex flex-column align-items-center ">
                    <div className="cookie-text mb-16px">
                        {langs.cookiesPolicy}
                        <Link to={`/${getLang()}/terms`} className="font-size-md-18 text-dark ml-8px border-bottom border-dark">
                            {langs.cookieLink}
                        </Link>
                        </div>
                    <div className="cookie-accept text-center text-white cursor-pointer bg-main-blue d-inline-block p-12px"
                        onClick={processCookieAgreement}>
                        {langs.agreement}
                    </div>
                </div>
            }
        </>
    )
};

export default Header;
