import React from 'react'
import {Form, Field, ErrorMessage} from 'formik';

const LangsFormComponent = (props) => {
    return (
        <div className="container">
            <Form autoComplete="off">
                <div className="form-group">
                    <label className="" htmlFor="Name">
                        Name
                    </label>
                    <Field name="Name" className="form-control radius-10" disabled />
                    <ErrorMessage name="Name" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <label className="" htmlFor="Ka">
                        Ka
                    </label>
                    <Field as="textarea" name="Ka" className="form-control radius-10" />
                    <ErrorMessage name="Ka" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <label className="" htmlFor="En">
                        En
                    </label>
                    <Field as="textarea" name="En" className="form-control radius-10" />
                    <ErrorMessage name="En" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <button type="submit" className={`btn btn-primary`} >
                        {props.langs.adminSave}
                    </button>
                </div>
            </Form>
        </div>
    )
}

export default LangsFormComponent