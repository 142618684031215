import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment'

import { AppDataContext } from 'contexts/app';
import arrow from '../../../resources/img/icons/arr.svg'
const CoursesCardLarge = (props) => {
    const { data } = props;
    const { lang, getLang, langs } = useContext(AppDataContext);
    const changeText = (text) => {
        return text.replace(langs.localPro, '').replace(langs.localBasic, '');
    }
    return (
        <div className="card-link h-100">
            <img src={data[0].outer_img} alt="" className="position-absolute w-100 h-100 top-0 left-0 object-fit-cover z-index-n1" />
            <div className="slide-info d-flex h-100 flex-column justify-content-center align-items-end p-24px p-md-36px p-lg-84px">
                {/* კურსის სახელი */}
                <div className="slide-descr font-base text-white font-size-24 font-size-md-72 clamp  ">
                    {changeText(data[0][`short_description_${lang}`])}
                </div>
                {data.map((item, i) => {
                    let basic = item[`short_description_ka`].indexOf(langs.localBasic) === -1 ? false : true;
                    return (
                        <Link to={`/${getLang()}/checkout?package=${item.id}`} className="course-link d-flex justify-content-center align-items-center font-size-36 font-base-caps text-white mt-16px">
                            {basic ? langs.localBasic : langs.localPro}
                            <div className="arrow w-40px h-40px rounded-circle border d-flex justify-content-center align-items-center ml-16px">
                                <img src={arrow} alt="" className='w-20px h-20px' />
                            </div>
                        </Link>
                    )
                })}
                {/* ლექტორი */}
                <div className="slide-title font-bold-caps text-white font-size-18 font-size-md-24 font-size-lg-48 clamp">
                    {data[0][`title_${lang}`]}
                </div>
            </div>
        </div>
    )
}
export default CoursesCardLarge