import React from 'react'

const NotFound = () => {

    return (
        <div  className='d-flex justify-content-center slign-items-cenetr mt-100px text-white font-size-48 '>
            NotFound
        </div>
    )
}

export default NotFound;