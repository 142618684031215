import LocalStorage from './local'
import CONFIG from './config';
class general {

    constructor() {
        this.storage = new LocalStorage();
    }

    processLanguage() {
        let cUrlPath  = window.location.pathname;
        let cUrlLang  = cUrlPath.split('/');
        let langs     = CONFIG.LANGS.VIEW
        let lang      = CONFIG.LANGS.DEFAULT_LANG
        
        if (cUrlLang.length < 2 || langs.indexOf(cUrlLang[1]) === -1) {
            if (this.storage.exists(CONFIG.STORAGE.LANGUAGE)) {
                let storageLang = this.storage.get(CONFIG.STORAGE.LANGUAGE);
                if (langs.indexOf(storageLang) !== -1) {
                    lang = storageLang;
                }
            }
            window.location.href = 'https://levelupedu.ge/' + lang + cUrlPath + window.location.search;
            return false;
        } else {
            this.storage.set(CONFIG.STORAGE.LANGUAGE, cUrlLang[1]);
        }
        return true;
    }
}

export default general;