import React, { useContext } from 'react'
import ReactPlayer from 'react-player'
import parse from 'html-react-parser';
import moment from 'moment'
import { Link } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { Header } from '../components'

import { AppDataContext } from 'contexts/app';
const Details = (props) => {

    const { lang, getLang, langs } = useContext(AppDataContext);
    return (
        <div className="position-relative mt-24px mt-lg-48px pb-100px">
            <Header {...props} />
            <div className="row mb-24px">
            <div className="single-course col-12 col-m-6 mt-24px">
                    <div className="single-course-img mb-24px">
                        <img src={props.data && props.data.inner_img} alt="" className="position-absolute w-100 h-100 top-0 left-0 object-fit-cover" />
                    </div>
                </div>
                <div className={`single-course-text-box  col-12 col-m-6 mt-24px`}>
                   <div className="course-namse font-size-48 text-white font-base-caps">{props.data && props.data[`short_description_${props.lang}`]}</div>
                    <h2 className='font-size-48px font-base-caps text-main-title mb-24px'>{langs.localSilabus}</h2>
                    <div className="single-course-text text-white font-size-18 font-base mt-8px">
                        {(props.data && props.data[`description_${props.lang}`]) && parse(props.data[`description_${props.lang}`])}
                    </div>
                </div>
       
            </div>
            <div className="row my-24px">
            {props.data.video &&
                    <div className="col-md-6 single-course-media-box ">
                        <ReactPlayer url={props.data.video} />
                    </div>
                }
            </div>
            <div className="register border-top">
            <Link to={`/${getLang()}/checkout?package=${props.data.id}`} className="course-link d-flex justify-content-start align-items-center font-size-36 font-base-caps text-white mt-16px"> 
               {langs.localRegister}
            </Link>
            </div>
        </div>
    )
};

export default Details;
