import React from 'react'

const Header = (props) => {
    return (
        <div className="row">
            <div className="d-flex overflow-hidden flex-wrap mt-36px mt-md-0 mb-8px w-100 col-12">
                <div className="">
                    <h1 className="font-size-32 font-size-m-64 font-size-lg-64 font-bold-caps mt-16 text-white">
                        {props.data  && props.data[`title_${props.lang}`]}
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default Header