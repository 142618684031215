import React, {useState} from 'react'
import * as Yup from 'yup';
import {Formik} from 'formik';

import {LoginFormComponent} from '../components'
const Login = (props) => {

    const [loginError, setLoginError]                   = useState(false)
    const config = {
        initialValues: {
            Email: '',
            Password: '',
        },
        validationSchema: Yup.object().shape({
            Email: Yup.string().email('Invalid email').required('Email is Required'),
            Password: Yup.string().required('Password is required'),
        }),
        enableReinitialize: true
    }

    const handleSubmit = async (values) => {
        let apiData = await props.api.adminLogin(values);
        if (apiData.statusCode && apiData.data.token) {
            let request = await props.login(apiData.data)
            if (request) {
                props.replace(`/${props.getLang()}/admin/home`, {}, true)
            } else {
                setLoginError(true)
            }
        } else {
            setLoginError(true)
        }
    }

    return (
        <div>
            <Formik {...config} onSubmit={handleSubmit} component={
                (formikProps) => <LoginFormComponent {...{loginError}} {...formikProps} />
            } />
        </div>
    )
};

export default Login;
