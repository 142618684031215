import React from 'react'
import {Form, Field, ErrorMessage} from 'formik';

const RegistrationFormComponent = (props) => {
    return (
        <div className="container">
            <Form autoComplete="off">

                <div className="form-group">
                    <label className="" htmlFor="Email">
                        Email
                    </label>
                    <Field name="Email" className="form-control radius-10" />
                    <ErrorMessage name="Email" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <label>
                        <Field type="checkbox" name="Status" value="1" checked={parseInt(props.values.Status) === 1} onChange={() => {
                            props.setFieldValue("Status", parseInt(props.values.Status) === 1 ? 0 : 1)
                        }} />
                         <span className="px-8px">Status</span>
                        
                    </label>
                    <ErrorMessage name="Status" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <button type="submit" className={`btn btn-primary`} >
                       {props.langs.adminSave}
                    </button>
                </div>
            </Form>
        </div>
    )
}

export default RegistrationFormComponent