import React from 'react'

import { CoursesCardSmall, Loading, Pagination } from '../../../global/components';
import { Header } from '../components'
const List = (props) => {
    return (
        <>
            <h1 className='text-white font-size-48 font-base-caps mb-28px'>{props.langs.localCourses}</h1>
            {(props.data && props.data.list && props.data.list.data.length > 0) ?
                <>
                    <div className="row mt-24px">
                        {props.data.list.data.map((index, i) => (
                            <div className="col-12 col-md-6  col-m-4 mb-36px " key={i}>
                                <div className="courses-box  position-relative w-100 h-100">
                                    <CoursesCardSmall {...{
                                        data: index,
                                        page: props.page
                                    }} />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="row d-flex justify-content-center mt-20px mt-sm-10px mt-lg-30px">
                        <Pagination {...props.paginate} />
                    </div>
                </>
                :
                <Loading />
            }
        </>
    )
}

export default List