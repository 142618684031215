import React, { useEffect, useState } from 'react';
import Swiper   from 'react-id-swiper'

export default React.forwardRef((props, ref) => {
    const [swiper, updateSwiper] = useState(null);
    const initial = () => {
        if (swiper !== null) {
            ref.current = swiper
        }
    }
    useEffect(initial, [swiper])

    return (
        <Swiper forwardedRef={ref} getSwiper={updateSwiper} {...props.settings} >
            {props.children}
        </Swiper>
    )
});