import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { animateScroll as scroll } from 'react-scroll';

const ScrollToTop = ({ history }) => {
	useEffect(() => {
		const unlisten = history.listen(() => {
			scroll.scrollToTop({
					smooth: true,
					duration: 100,
					delay: 0,
				});
			});
			return () => {
				unlisten();
			}
		}, [history]);
    return (null);
}

export default withRouter(ScrollToTop);
