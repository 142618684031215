import React, { useState, useEffect, createContext } from 'react';
import { useHistory } from 'react-router-dom';

import queryString from 'query-string';

export const HistoryContext = createContext();

export const HistoryProvider = (props) => {
    const history = useHistory();

    const getQueryString = () => {
        return queryString.parse(history.location.search)
    }

    const [historyList, setHistoryList]         = useState([]);
    const [params, setParams]                   = useState(getQueryString());
    const [mobileMenu, setMobileMenu]           = useState(false);
    

    const getPathname = () => {
        return history.location.pathname
    }

    const getPagename = () => {
        let url = getPathname().split("/")
        return url[2] ? url[2] : false
    }

    const changePage = (page) => {
        let url = getPathname().split("/")
        url[2] = page
        return url.join("/")
    }

    const stringify = (priority = {}, noParams = false, reverse = false) => {
        let config = reverse ? Object.assign({}, priority, params) : Object.assign({}, params, priority)
        return queryString.stringify(!noParams ? config : priority, {
            skipNull: true,
            skipEmptyString: true
        });
    }

    const push = (url, priority = {}, clean = false) => {
        if (clean) { remove(0) }
        return history.push(url + '?' + stringify(priority), { hash: true })
    }

    const replace = (url, priority = {}, clean = false) => {
        return history.replace(`${url}${!clean ? `?${stringify(priority)}` : ''}`, { hash: true })
    }

    const remove = (type = 0, set = [], newSet = false) => {
        //type = 0 => remove All / 1 => remove Provided [] / 2 => remove All but Provided
        Object.entries(params).map(([k]) => {
            if (type === 0 || (type === 1 && set.includes(k)) || (type === 2 && !set.includes(k))) {
                delete params[k]
            }
            return k;
        })
        //  Clean all / Add New / Push
        if (newSet) {
            assignParam(newSet, true)
        }
    }

    const checkConditions = (name, value) => {
        let check = 0
        if (name === 'CatID' && value === 0){
            delete params.CatID
            check++
        }

        return check > 0 ? false : true;
    }

    const assignParam = (set, pushURL = false) => {
        let check = 0
        let list = {...set}
        Object.entries(list).map(([name, value]) => {
            if (!checkConditions(name, value)) {
                check++
            }
            return true;
        })
        
        if (check === 0) {
            setParams(Object.assign({}, params, set));
        } else {
            return false
        }

        if (pushURL) {
            push(getPathname(), set)
        }
        
    }

    const processList = (action, url) => {
        let value = {Action: action, URL: url}
        let list = historyList
        if (list.length > 5) {
            list.splice(0,1)
        }
        list.push(value)
        setHistoryList(list)
    }

    const processDependencies = () => {
        // console.log(getQueryString())
        let query = getQueryString()
        // console.log('Processed')
        setParams(query)
    }

    const historyListen = () => {
        return history.listen((location, action) => {
            processList(action, location.pathname + "?" + stringify(getQueryString()));
            // console.log('History Listen:')
            // console.log(location)
            // console.log(action)
            setMobileMenu(false)
            processDependencies();
        }) 
    }

    const initial = () => {
        historyListen()
    }

    useEffect(initial, [history])

    return (
        <HistoryContext.Provider value={{
            params,
            setParams,
            mobileMenu,
            setMobileMenu,
            getQueryString,
            getPathname,
            stringify,
            push,
            replace,
            remove, 
            assignParam, 
            changePage, 
            getPagename, 
            historyList,
            history
        }}>
            {props.children}
        </HistoryContext.Provider>
    )
}