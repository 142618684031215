import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import * as Yup from 'yup';
import { Formik } from 'formik';

import { Loading, Pagination } from '../../../global/components';
import { Sidebar, LangsFormComponent } from '../components'

const Langs = (props) => {
    const [list, setList] = useState(false);
    const [paginate, setPaginate]                                   = useState(null);
    const [keyword, setKeyword]                                     = useState('');

    const [single, setSingle] = useState({
        Name: '',
        Ka: '',
        En: '',
    });

    const config = {
        initialValues: single,
        validationSchema: Yup.object().shape({
            Ka: Yup.string().required('Ka is required'),
            En: Yup.string().required('En is required')
        }),
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
    }

    const handleSubmit = async (values) => {
        if (props.api.routeParams.action === "edit" && props.api.routeParams.id) {
            let apiData = await props.api.updateLangs(props.api.routeParams.id, values)
            props.onAction(apiData.statusCode, 'langs')
        }
    }

    const fetchData = async () => {
        let apiData = await props.api.getLangsList(
            props.api.routeParams.id ? props.api.routeParams.id : false,
            !props.api.routeParams.id ? props.stringify({ Limit: 10, Page: props.params.Page ? props.params.Page : 1, Keyword: keyword }) : false
        );
        if (apiData.statusCode) {
            if (props.api.routeParams.id) {
                setSingle({
                    Name: apiData.data.name,
                    Ka: apiData.data.ka,
                    En: apiData.data.en,
                })
            } else {
                setList(apiData.data.data);
                setPaginate({
                    pageCount: apiData.data.pagination.totalPages,
                    forcePage: props.params.Page !== null && props.params.Page > 0 ? props.params.Page - 1 : 0,
                    onPageChange: (data) => {
                        props.assignParam({ Page: data.selected + 1 }, true)
                    }
                });
            }
        }
    };

    const initial = () => {
        fetchData()
    }

    useEffect(initial, [props.params]); 

    const changeKeyword = (e) => {
        e.preventDefault()
        setKeyword(e.target.value)
    }

    const onKeyword = () => {
        if (keyword.length >= 1 || keyword.length === 0 && list) {
            const timer = setTimeout(() => {
                fetchData()
            }, 500);
            return () => clearTimeout(timer);
        }
    }

    useEffect(onKeyword, [keyword]);


    const ActiveComponent = (props) => {
        if (props.api.routeParams && props.api.routeParams.action === "edit") {
            return <Formik {...config} onSubmit={handleSubmit} component={
                (formikProps) => <LangsFormComponent {...{
                    getLang: props.getLang,
                    langs: props.langs
                }} {...formikProps} />
            } />
        } else if (list) {
            return (
                <div className="border border-blue border-radius-md p-12px">
                    <div className="row mb-12px">
                        <div className="col">ID</div>
                        <div className="col">ka</div>
                        <div className="col">en</div>
                        <div className="col">{props.langs.adminActions}</div>
                    </div>
                    {list.map((item, i) => (
                        <div key={i} className="row">
                            <div className="col">
                                {item.id}
                            </div>
                            <div className="col">
                                {item.ka}
                            </div>
                            <div className="col">
                                {item.en}
                            </div>
                            <div className="col">
                                <Link to={`/${props.getLang()}/admin/langs/edit/${item.id}`} className="btn btn-primary mr-8px mb-8px font-size-18px" >
                                     {props.langs.adminEdit}
                                </Link>
                            </div>
                        </div>
                    ))}
                    <div className="row d-flex justify-content-center mt-20px mt-sm-10px mt-lg-30px">
                        <Pagination {...paginate} />
                    </div>
                </div>
            )
        } else {
            return <Loading />
        }
    }

    return (
        <div className="row align-items-start">
            <Sidebar {...props} />
            <div className="col-m-9 admin-content">
                <div className="form-group col-4">
                    <input type="text" className="form-control "
                        value={keyword}
                        placeholder={props.langs.localSearch}
                        onChange={changeKeyword} 
                    />
                </div>
                <ActiveComponent {...props} />
            </div>
        </div>
    )
};

export default Langs;
