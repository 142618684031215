import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment'
import logo from '../../../resources/img/icons/logo.svg'
import fragment from '../../../resources/img/icons/fragment.svg'
import { AppDataContext } from 'contexts/app';

const CoursesCardSmall = (props) => {
    const { lang, getLang, langs }                  =  useContext(AppDataContext);
    return (
        <Link to={`/${getLang()}/${props.page}/${props.data.id}`} className="card-link">
            <img src={props.data.inner_img} alt="" className="position-absolute w-100 h-100 top-0 left-0 object-fit-cover" />
            <div className="card-logo">
                <img src={logo} alt="" />
            </div>
            <div className="courses-info h-100px  bg-white d-flex flex-column justify-content-end p-16px   position-relative">
                <div className="courses-title  font-bold-caps text-main-blue font-size-18 font-size-lg-21 clamp">
                    {props.data[`title_${lang}`]}
                </div>
                <div className="courses-descr font-bold-caps text-main-title font-size-18 font-size-lg-24  clamp _2">
                    {props.data[`short_description_${lang}`]}
                </div>
                <div className="fragment">
                    <img src={fragment} alt="" />
                </div>
            </div>
        </Link>
    )
}

export default CoursesCardSmall