import React from 'react'
import { Link } from 'react-router-dom';
import {Form, Field, ErrorMessage} from 'formik';

const ProfileFormComponent = (props) => {
    const {langs} = props
    return (
        <div className="pt-16px border-top">
			<Form autoComplete="off">
				<div className="form-group">
					<label className="" htmlFor="Name">
						Name
					</label>
					<Field name="Name" className="form-control radius-10" />
					<ErrorMessage name="Name" component="label" className="error text-danger pl-25px" />
				</div>
				<div className="form-group">
					<label className="" htmlFor="Lastname">
						Lastname
					</label>
					<Field name="Lastname" className="form-control radius-10" />
					<ErrorMessage name="Lastname" component="label" className="error text-danger pl-25px" />
				</div>
				<div className="form-group">
					<label className="" htmlFor="Email">
						Email
					</label>
					<Field name="Email" className="form-control radius-10" disabled />
					<ErrorMessage name="Email" component="label" className="error text-danger pl-25px" />
				</div>
				<div className="form-group">
					<label className="" htmlFor="Phone">
						Phone
					</label>
					<Field name="Phone" className="form-control radius-10" />
					<ErrorMessage name="Phone" component="label" className="error text-danger pl-25px" />
				</div>
				<div className="form-group">
					<label className="" htmlFor="Password">
						Password
					</label>
					<Field type="password" name="Password" className="form-control radius-10" />
					<ErrorMessage name="Password" component="label" className="error  text-danger pl-25px" />
				</div>
				<div className="form-group">
					<label className="" htmlFor="PasswordConfirm">
						PasswordConfirm
					</label>
					<Field type="password" name="PasswordConfirm" className="form-control radius-10" />
					<ErrorMessage name="PasswordConfirm" component="label" className="error  text-danger pl-25px" />
				</div>
				<div className="form-group">
					<button type="submit" className={`btn btn-primary`} >
						{langs.adminEdit}
					</button>
				</div>
			</Form>
		</div>
    )
}

export default ProfileFormComponent