import homeRoute                    from './home'
import coursesRoute                 from './courses'
import aboutRoute                   from './about'
import contactRoute                 from './contact'
import registrationRoute            from './registration'
import adminRoute                   from './admin'
import userRoute                    from './user'
import checkoutRoute                from './checkout'
import termsRoute                   from './terms'

export { Header }                   from './header'
export { Footer }                   from './footer'

export const routes = [
    homeRoute,
    coursesRoute,
    aboutRoute,
    contactRoute,
    registrationRoute,
    adminRoute,
    userRoute,
    checkoutRoute,
    termsRoute
]
