import React, { useState, useEffect } from 'react'

import * as Yup from 'yup';
import { Formik } from 'formik';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { Sidebar, AboutFormComponent } from '../components'

const About = (props) => {
    const [single, setSingle] = useState({
        LeftText_ka: EditorState.createEmpty(),
        LeftText_en: EditorState.createEmpty(),
        RightText_ka: EditorState.createEmpty(),
        RightText_en: EditorState.createEmpty(),
        LeftImage: '',
        RightImage: ''
    });

    const config = {
        initialValues: single,
        validationSchema: Yup.object().shape({}),
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
    }

    const processEditorState = (data) => {
        const blocksFromHtml = htmlToDraft(data)
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        return contentState
    }

    const handleSubmit = async (values) => {
        let finalData = {...values}
        finalData.LeftText_ka = draftToHtml(convertToRaw(finalData.LeftText_ka.getCurrentContent()))
        finalData.LeftText_en = draftToHtml(convertToRaw(finalData.LeftText_en.getCurrentContent()))
        finalData.RightText_ka = draftToHtml(convertToRaw(finalData.RightText_ka.getCurrentContent()))
        finalData.RightText_en = draftToHtml(convertToRaw(finalData.RightText_en.getCurrentContent()))
        let apiData = await props.api.updateAbout(finalData)
        props.onAction(apiData.statusCode)
    }

    const uploadImages = async (files, form) => {
        let newList = []
        if (form.values[form.imgFieldName].indexOf(",") > -1) {
            newList = form.values[form.imgFieldName].split(',')
        } else if (form.values[form.imgFieldName].length > 0) {
            newList = [form.values[form.imgFieldName]]
        }
        // console.log(newList)
        const data = await props.api.uploadFiles(files)
        if (data) {
            form.setFieldValue(form.imgFieldName, newList.concat(data.data).join(','))
            return data
        }
        return false
    }

    const fetchData = async () => {
        let apiData = await props.api.getAboutAdmin();
        if (apiData.statusCode) {
            setSingle({
                LeftText_ka: EditorState.createWithContent(processEditorState(apiData.data.left_text_ka)),
                LeftText_en: EditorState.createWithContent(processEditorState(apiData.data.left_text_en)),
                RightText_ka: EditorState.createWithContent(processEditorState(apiData.data.right_text_ka)),
                RightText_en: EditorState.createWithContent(processEditorState(apiData.data.right_text_en)),
                LeftImage: apiData.data.left_image,
                RightImage: apiData.data.right_image
            })
        }
    };

    const initial = () => {
        fetchData()
    }

    useEffect(initial, []);

    const ActiveComponent = (props) => {
        return <Formik {...config} onSubmit={handleSubmit} component={
                (formikProps) => <AboutFormComponent {...{
                    getLang: props.getLang,
                    langs: props.langs,
                    uploadImages
                }} {...formikProps} />
        }/>
    }

    return (
        <div className="row align-items-start">
            <Sidebar {...props} />
            <div className="col-m-9 admin-content">
                <ActiveComponent {...props} />
            </div>
        </div>
    )
};

export default About;
