import React from 'react'
import { Link } from 'react-router-dom';
import {Form, Field, ErrorMessage} from 'formik';

const RegistrationFormComponent = (props) => {
	const {submit, langs, getLang} = props
	return (
		<div>
			<Form autoComplete="off">
				<div className="form-group">
					<label className="" htmlFor="Name">
						{langs.localName}
					</label>
					<Field name="Name" className="form-control radius-10" />
					<ErrorMessage name="Name" component="label" className="error text-danger pl-25px" />
				</div>
				<div className="form-group">
					<label className="" htmlFor="Lastname">
						{langs.localLastName}
					</label>
					<Field name="Lastname" className="form-control radius-10" />
					<ErrorMessage name="Lastname" component="label" className="error text-danger pl-25px" />
				</div>
				<div className="form-group">
					<label className="" htmlFor="Email">
						{langs.localEmail}
					</label>
					<Field name="Email" className="form-control radius-10" />
					<ErrorMessage name="Email" component="label" className="error text-danger pl-25px" />
				</div>
				<div className="form-group">
					<label className="" htmlFor="Phone">
						{langs.localPhone}
					</label>
					<Field name="Phone" className="form-control radius-10" />
					<ErrorMessage name="Phone" component="label" className="error text-danger pl-25px" />
				</div>
				<div className="form-group">
					<label className="" htmlFor="Password">
						{langs.localPassword}
					</label>
					<Field type="password" name="Password" className="form-control radius-10" />
					<ErrorMessage name="Password" component="label" className="error  text-danger pl-25px" />
				</div>
				<div className="form-group">
					<label className="" htmlFor="PasswordConfirm">
						{langs.localPasswordConfirm}
					</label>
					<Field type="password" name="PasswordConfirm" className="form-control radius-10" />
					<ErrorMessage name="PasswordConfirm" component="label" className="error  text-danger pl-25px" />
				</div>
				<div className="form-group text-center">
					<button type="submit" className={`btn btn-primary`} disabled={submit} >
						{langs.localRegistration}
					</button>
				</div>
			</Form>
			<div className="mt-24px font-size-18 text-white text-center font-base-caps">
				<Link to={`/${getLang()}/user/login`} className="text-white">
					{langs.localAuthorization}
				</Link>
			</div>
		</div>
	)
}

export default RegistrationFormComponent