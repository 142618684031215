import React, { useState, useEffect } from 'react'

import * as Yup from 'yup';
import { Formik } from 'formik';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { Sidebar, ContactFormComponent } from '../components'

const Contact = (props) => {
    const [single, setSingle] = useState({
        Title_ka: '',
        Title_en: '',
        Text_ka: EditorState.createEmpty(),
        Text_en: EditorState.createEmpty()
    });

    const config = {
        initialValues: single,
        validationSchema: Yup.object().shape({
            Title_ka: Yup.string().required('Title_ka is required'),
        }),
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
    }

    const processEditorState = (data) => {
        const blocksFromHtml = htmlToDraft(data)
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        return contentState
    }

    const handleSubmit = async (values) => {
        let finalData = {...values}
        finalData.Text_ka = draftToHtml(convertToRaw(finalData.Text_ka.getCurrentContent()))
        finalData.Text_en = draftToHtml(convertToRaw(finalData.Text_en.getCurrentContent()))
        let apiData = await props.api.updateContact(finalData)
        props.onAction(apiData.statusCode)
    }

    const fetchData = async () => {
        let apiData = await props.api.getContact();
        if (apiData.statusCode) {
            setSingle({
                Title_ka: apiData.data.title_ka,
                Title_en: apiData.data.title_en,
                Text_ka: EditorState.createWithContent(processEditorState(apiData.data.text_ka)),
                Text_en: EditorState.createWithContent(processEditorState(apiData.data.text_en)),
            })
        }
    };

    const initial = () => {
        fetchData()
    }

    useEffect(initial, [props.params]);

    const ActiveComponent = (props) => {
        return <Formik {...config} onSubmit={handleSubmit} component={
                (formikProps) => <ContactFormComponent {...{
                    getLang: props.getLang,
                    langs: props.langs
                }} {...formikProps} />
        }/>
    }

    return (
        <div className="row align-items-start">
            <Sidebar {...props} />
            <div className="col-m-9 admin-content">
                <ActiveComponent {...props} />
            </div>
        </div>
    )
};

export default Contact;
