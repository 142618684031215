import React, {createContext} from 'react';

import { API } from 'global/services';
import CONFIG from 'global/services/config';

export const UserContext = createContext();

export const UserProvider = (props) => {
	const api 			= new API();

	const getStorageAuth = () => {
		return api.storage.get(CONFIG.STORAGE.AUTH)
	}

	const getUserData = () => {
		return api.storage.getJson(CONFIG.STORAGE.USER)
	}

	const unsetStorageData = () => {
		api.storage.clear();
		return true;
	}

	const setAccessToken = (AccessToken) => {
		if (!AccessToken) {return false;}
		return api.storage.set(CONFIG.STORAGE.AUTH, AccessToken)
	}

	const setUserData = (data) => { 
		if (!data) {return false;}
		return api.storage.set(CONFIG.STORAGE.USER, JSON.stringify(data))
	}

	const checkIfAuthorized = () => {
		let storage = getStorageAuth()
		return storage ? true : false
	}

	const login = (data) => {
		setAccessToken(data.token)
		setUserData(data.user)
		if (getStorageAuth()) {
			return true;
		}
		return false;
	}

	const logout = async (e) => {
		e && (e.preventDefault() || e.stopPropagation());
		let request = await api.logout();
		if (request) {
			if (unsetStorageData()) {
				window.location.href = `${CONFIG.HOST.APP_URL}`;
			}
		}
	}

	return (
		<UserContext.Provider value={{checkIfAuthorized, getUserData, login, logout}}>
			{props.children}
		</UserContext.Provider>
	);
};