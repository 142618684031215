import React, {useState, useEffect} from 'react'
import { useDropzone } from 'react-dropzone';
import noImage from '../../../resources/img/no-image.jpg'
export default (props) => {
    const [files, setFiles]                                 = useState(props.values[props.imgFieldName] ? [{existing : props.values[props.imgFieldName]}] : []);
    const [filesTriger, setFilesTriger]                     = useState(0);

    const filesProcess = () => {
        if (files.length > 0) {
            files.forEach(file => {
                if (file.existing) {
                    Object.assign(file, {preview: file.existing})
                } else {
                    const image = URL.createObjectURL(file)
                    if (image) {
                        Object.assign(file, { preview: image})
                    }
                }
            });
        }
    }

    useEffect(filesProcess, [files, filesTriger]);

    const deleteFile = (e, index) => {
        e.preventDefault() || e.stopPropagation();
        let reorder = files
        reorder.splice(index, 1)
        setFiles(reorder)
        setFilesTriger(filesTriger + 1)
        reorder = props.values[props.imgFieldName].split(',')
        reorder.splice(index, 1)
        props.setFieldValue(props.imgFieldName, reorder.join(','))
    }

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        accept: 'image/*',
        initialFiles: props.values[props.imgFieldName],
        onDropAccepted: async acceptedFiles => {
            if (acceptedFiles.length > 1 || files.length >= 1 || (acceptedFiles.length + files.length) > 1) {
                return false;
            }
            let action = await props.uploadImages(acceptedFiles, props)
            if (action) {
                let newlyUploaded = acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }))
                setFiles(files.concat(newlyUploaded))
            } else {
                return false;
            }
        }
    });

    const ImageHandler = (props) => {
        const error = (e) => {
            e.onError = null;
            e.target.src = noImage
        }
        return (
            <img className={props.className} src={props.src ? props.src : noImage} alt={props.alt} onError={error}/>
        )
    }

    return (
        <div className={`form_box border border-blue  d-flex align-items-center justify-content-start w-100 img-upload-box border-radius-lg 
            ${files.length > 0 ? 'bg-white px-16px pb-12px pt-24px' : 'bg-transparent'}`}>
            <div className="row w-100 mx-auto">
                {files.map((file, i) => (
                    <div className='files-preview-thumbs d-inline-block col-3 col-md-2 px-6px' key={i}>
                        <div className="uploaded-img">
                            {file && 
                                <ImageHandler {...{
                                    src: file.preview,
                                    alt: file.name,
                                    className: "w-100 border-radius-md preview-boxs-img"
                                }} />
                            }
                            <button className="btn btn-danger remove-img my-8px " onClick={e => deleteFile(e, i)} title={"სურათის წაშლა"}>
                                <i className="svg-i svg-i img-delete-i"></i>
                                {props.langs.adminDelete}
                            </button>
                        </div>
                    </div>
                ))}
                {files.length < 1 &&
                    <div className={`file_input_inner  cursor-pointer px-12px mb-0  outline-none 
                        ${files.length > 0 ? 'files-preview-thumbs col-3 col-md-2 px-0 add-more-img p-0 d-inline-block' : 'd-flex pb-0 -24px p-md-40px border-radius-lg w-100'}`} 
                        {...getRootProps()} >
                        <div className="uploaded-img mx-auto py-24px py-lg-0">
                            <input {...getInputProps()} name={props.imgFieldName} id={props.imgFieldName} />
                            <div className={`${files.length > 0 ? '' : 'w-100 text-center'}`}>
                                <span className={`${files.length > 0 ? 'add-more-img-i' : 'd-flex justify-content-center '}`}>
                                    <i className={`svg-i svg-i-lg  ${files.length > 0 ? 'add-more-img' : '  add-photo-icon-i'}`}></i>
                                </span>
                                <span className={`${files.length > 0 ? 'd-none' : 'font-weight-bold font-size-14 text-dark d-flex justify-content-center mt-8px '}`}>
                                    {"სურათის ატვირთვა"}
                                </span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}