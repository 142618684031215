import React from 'react'
import {Form, ErrorMessage} from 'formik';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { UploadFormComponent } from '../components'

const AboutFormComponent = (props) => {
    const toolbarSettings = {
        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link'],
    }
    const changeEditorStateLKa = (newState) => {
        props.setFieldValue('LeftText_ka', newState)
    }
    const changeEditorStateLEn = (newState) => {
        props.setFieldValue('LeftText_en', newState)
    }
    const changeEditorStateRKa = (newState) => {
        props.setFieldValue('RightText_ka', newState)
    }
    const changeEditorStateREn = (newState) => {
        props.setFieldValue('RightText_en', newState)
    }
    return (
        <div className="container">
            <Form autoComplete="off">
                <div className="form-group">
                    <label className="" htmlFor="LeftText_ka">
                        {props.langs.adminText} {props.langs.adminLeft} {props.langs.localIn_ka}
                    </label>
                    <div className="border radius-10">
                        <Editor
                            editorState={props.values.LeftText_ka}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={changeEditorStateLKa}
                            toolbar={toolbarSettings}
                        />
                    </div>
                    <ErrorMessage name="LeftText_ka" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <label className="" htmlFor="LeftText_en">
                    {props.langs.adminText} {props.langs.adminLeft} {props.langs.localIn_en}
                    </label>
                    <div className="border radius-10">
                        <Editor
                            editorState={props.values.LeftText_en}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={changeEditorStateLEn}
                            toolbar={toolbarSettings}
                        />
                    </div>
                    <ErrorMessage name="LeftText_en" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <label className="" htmlFor="RightText_ka">
                        {props.langs.adminText} {props.langs.adminRight} {props.langs.localIn_ka}
                    </label>
                    <div className="border radius-10">
                        <Editor
                            editorState={props.values.RightText_ka}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={changeEditorStateRKa}
                            toolbar={toolbarSettings}
                        />
                    </div>
                    <ErrorMessage name="RightText_ka" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <label className="" htmlFor="RightText_en">
                        {props.langs.adminText} {props.langs.adminRight} {props.langs.localIn_en}
                    </label>
                    <div className="border radius-10">
                        <Editor
                            editorState={props.values.RightText_en}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={changeEditorStateREn}
                            toolbar={toolbarSettings}
                        />
                    </div>
                    <ErrorMessage name="RightText_en" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <label className="" htmlFor="">
                        {props.langs.adminImage} {props.langs.adminLeft}
                    </label>
                  <UploadFormComponent {...props} {...{imgFieldName: 'LeftImage'}} />
                </div>
                <div className="form-group">
                    <label className="" htmlFor="">
                        {props.langs.adminImage} {props.langs.adminRight}
                    </label>
                 <UploadFormComponent {...props} {...{imgFieldName: 'RightImage'}} />
                </div>
                <div className="form-group my-8px">
                    <button type="submit" className={`btn btn-primary`} >
                        {props.langs.adminSave}
                    </button>
                </div>
            </Form>
        </div>
    )
}

export default AboutFormComponent