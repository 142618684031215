import React from 'react'

const Header = (props) => {
    return (
        <div className="row">
            <div className="d-flex overflow-hidden flex-wrap mt-36px mt-md-0 mb-8px w-100 col-12">
                <div className="">
                    {/* <h3 className="font-size-18 font-size-md-24 font-bold-caps ">
                        {props.langs.localGlobalTitle}
                    </h3> */}
                    <h1 className="font-size-32 text-white font-size-m-64  font-bold-caps ">{props.langs.localAboutUs}</h1>
                </div>
            </div>
        </div>
    )
}

export default Header