import React from 'react'

const PrivatePage = () => {

    return (
        <div>
            PrivatePage
        </div>
    )
}

export default PrivatePage