import React from 'react'
import {Form, Field, ErrorMessage} from 'formik';

const LoginFormComponent = (props) => {
    return (
        <div>
            <Form autoComplete="off">
                <div className="form-group">
                    <label className="" htmlFor="Email">
                        Email
                    </label>
                    <Field name="Email" className="form-control radius-10" />
                    <ErrorMessage name="Email" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <label className="" htmlFor="Password">
                        Password
                    </label>
                    <Field type="password" name="Password" className="form-control radius-10" />
                    <ErrorMessage name="Password" component="label" className="error  text-danger pl-25px" />
                </div>
                {props.loginError && 
                    <div className="">
                        Login Error
                    </div>
                }
                <div className="form-group">
                    <button type="submit" className={`btn btn-primary`} >
                        Login
                    </button>
                </div>
            </Form>
        </div>
    )
}

export default LoginFormComponent