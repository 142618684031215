import React, {useState} from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { AppDataProvider } from './contexts/app';
import { HistoryProvider } from './contexts/history';
import { UserProvider } from './contexts/user';

import {general, NotFound, PrivatePage} from 'global';
import {Loading, Meta, ScrollToTop} from 'global/components';
import {Header, Footer, routes} from 'modules'
import './resources/styles/index.scss';
// import 'bootstrap/dist/css/bootstrap.min.css';
const App = () => {
	
	const constructor = new general();
	const detectLang = constructor.processLanguage()

	const [menuOpen, setMenuOpen] = useState(false)
	const closeMenu = () => setMenuOpen(false)
	const openMenu = () => setMenuOpen(true)

	const PrivateRoute = ({ component: Component, ...config }) => (
		<Route {...config} render={(props) => {
			return constructor.user ? 
				<Component {...props} /> 
			: 
				<PrivatePage />
		}} />
	)
	return detectLang ?
		<Router>
			<ScrollToTop />
			<AppDataProvider>
			<HelmetProvider>
			<HistoryProvider>
			<UserProvider>
				<div id="globalContainer" className={`${menuOpen ? 'menu-open' : ''}`}>
					<Meta {...{ lang : constructor.storage.get("Lang") }}/>
					<Header {...{closeMenu, openMenu}}/>
					<main>
						<Switch>
							{routes.map((config, i) => {
								return config.auth === true ?
									<PrivateRoute {...config} key={i} />
								:
									<Route {...config} key={i} />
							})}
							<Route {...{component: NotFound}} />
						</Switch>
					</main>
					<Footer />
				</div>
			</UserProvider>
			</HistoryProvider>
			</HelmetProvider>
			</AppDataProvider>
		</Router>
	: <Loading />
};

export default App