
import React, { useState, useContext, useEffect } from 'react'
import { AppDataContext } from 'contexts/app';
import { UserContext } from 'contexts/user';
import { HistoryContext } from 'contexts/history';

import { API } from '../../../global'

import {Login, Orders, Profile, Registration} from '../components'

const View = () => {
	const { getLang, langs, lang } = useContext(AppDataContext);
	const { login, checkIfAuthorized } = useContext(UserContext);
	const { replace, stringify, params, push }	= useContext(HistoryContext);

	const [active, setActive] = useState(false)
	const api  = new API()

	const nav = ['login', 'registration', 'orders', 'profile']

	const checkRedirectCheckOut = () => {
		if (params.package) {
			replace(`/${lang}/checkout`, {})
		} else {
			replace(`/${lang}/user/orders`, {}, true)
		}
	}

	const initial = () => {
		let page = nav.find(o => o === api.routeParams.page)
		setActive( page ?? nav[0] )
	}

	useEffect(initial, [api.routeParams])
	return (
		<div className="container mt-64px position-relative">
			<div className="d-flex justify-content-center">
				{(active === 'orders' && checkIfAuthorized()) ?
					<Orders {...{
						langs,
						lang,
						api,
						stringify,
						getLang,
						replace,
						push
					}}/>
				: (active === 'profile' && checkIfAuthorized()) ?
					<Profile {...{
						api,
						langs,
						getLang,
					}}/>
				: active === 'registration' ? 
					<Registration {...{
						api,
						langs,
						getLang,
						login,
						checkRedirectCheckOut
					}}/>
				: 
					<Login {...{
						api,
						langs,
						getLang,
						login,
						checkRedirectCheckOut
					}}/>
				}
			</div>
		</div>
	)
};

export default View;
