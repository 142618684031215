import React from 'react'
import {Link} from 'react-router-dom'
import { Sidebar } from '../components'
const Home = (props) => {

    return (
        <div className="row">
            <Sidebar {...props} />
            <div className="col-m-9">
                <div className="row">
                    <div className="col-m-4 mb-12px ">
                        <div className="border border-blue border-radius-md p-10px text-center">
                            <Link to={`/${props.getLang()}/admin/courses`} className="text-white">
                                {props.langs.adminCourses}
                            </Link>
                        </div>
                    </div>
                    <div className="col-m-4 mb-12px ">
                        <div className="border border-blue border-radius-md p-10px text-center">
                            <Link to={`/${props.getLang()}/admin/about`} className="text-white">
                                {props.langs.adminAboutPage}
                            </Link>
                        </div>
                    </div>
                    <div className="col-m-4 mb-12px ">
                        <div className="border border-blue border-radius-md p-10px text-center">
                            <Link to={`/${props.getLang()}/admin/contact`} className="text-white">
                                {props.langs.adminContactPage}
                            </Link>
                        </div>
                    </div>
                    <div className="col-m-4 mb-12px ">
                        <div className="border border-blue border-radius-md p-10px text-center">
                            <Link to={`/${props.getLang()}/admin/terms`} className="text-white">
                                {props.langs.adminTermsPage}
                            </Link>
                        </div>
                    </div>
                    <div className="col-m-4 mb-12px ">
                        <div className="border border-blue border-radius-md p-10px text-center">
                            <Link to={`/${props.getLang()}/admin/registration`} className="text-white">
                                {props.langs.adminRegistration}
                            </Link>
                        </div>
                    </div>
                    <div className="col-m-4 mb-12px ">
                        <div className="border border-blue border-radius-md p-10px text-center">
                            <Link to={`/${props.getLang()}/admin/orders`} className="text-white">
                                {props.langs.localOrders}
                            </Link>
                        </div>
                    </div>
                    <div className="col-m-4 mb-12px ">
                        <div className="border border-blue border-radius-md p-10px text-center">
                            <Link to={`/${props.getLang()}/admin/langs`} className="text-white">
                                {props.langs.adminLangs}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Home;
