import React from 'react'
import moment from 'moment';
import {Form, Field, ErrorMessage} from 'formik';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { UploadFormComponent } from '../components'

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ka from "date-fns/locale/ka"; 
registerLocale("ka", ka);

const CoursesFormComponent = (props) => {
    const uploadImageCallBack = async (image) => {
        let result = await props.uploadContentImage([image])
        return { 
            data: { 
                link: result ? result : ''
            }
        }
    }
    const toolbarSettings = {
        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'image'],
        image: {
            urlEnabled: false,
            uploadEnabled: true,
            uploadCallback: uploadImageCallBack,
            previewImage: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
          }
    }
    const changeEditorStateKa = (newState) => {
        props.setFieldValue('Description_ka', newState)
    }
    const changeEditorStateEn = (newState) => {
        props.setFieldValue('Description_en', newState)
    }
    return (
        <div className="container">
            <Form autoComplete="off">
                <div className="form-group">
                    <label className="" htmlFor="GroupID">
                        {`GroupID`}
                    </label>
                    <Field name="GroupID" className="form-control radius-10" />
                    <ErrorMessage name="GroupID" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <label className="" htmlFor="Price">
                        {props.langs.localPrice}
                    </label>
                    <Field name="Price" className="form-control radius-10" />
                    <ErrorMessage name="Price" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <label className="" htmlFor="Title_ka">
                        {props.langs.adminTitle} {props.langs.localIn_ka}
                    </label>
                    <Field name="Title_ka" className="form-control radius-10" />
                    <ErrorMessage name="Title_ka" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <label className="" htmlFor="Title_en">
                        {props.langs.adminTitle} {props.langs.localIn_en}
                    </label>
                    <Field name="Title_en" className="form-control radius-10" />
                    <ErrorMessage name="Title_en" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <label className="" htmlFor="ShortDescription_ka">
                        {props.langs.adminShortDescription} {props.langs.localIn_ka}
                    </label>
                    <Field as="textarea" name="ShortDescription_ka" className="form-control radius-10" />
                    <ErrorMessage name="ShortDescription_ka" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <label className="" htmlFor="ShortDescription_en">
                        {props.langs.adminShortDescription} {props.langs.localIn_en}
                    </label>
                    <Field as="textarea" name="ShortDescription_en" className="form-control radius-10" />
                    <ErrorMessage name="ShortDescription_en" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <label className="" htmlFor="Description_ka">
                            {props.langs.adminLongDescription} {props.langs.localIn_ka}
                    </label>
                    <div className="border radius-10">
                        <Editor
                            editorState={props.values.Description_ka}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={changeEditorStateKa}
                            toolbar={toolbarSettings}
                        />
                    </div>
                    <ErrorMessage name="Description_ka" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <label className="" htmlFor="Description_en">
                            {props.langs.adminLongDescription} {props.langs.localIn_en}
                    </label>
                    <div className="border radius-10">
                        <Editor
                            editorState={props.values.Description_en}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={changeEditorStateEn}
                            toolbar={toolbarSettings}
                        />
                    </div>
                    <ErrorMessage name="Description_en" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group ">
                    <div className="d-inline-block px-12px border border-blue border-radius-md">
                    <DatePicker
                        selected={props.values.Date ? new Date(props.values.Date) : null}
                        onChange={date => {
                            props.setFieldValue("Date", moment(new Date(date)).format('YYYY-MM-DD').toString())
                        }}
                        dateFormat="dd/MM/yyyy"
                        locale={"ka"}
                        isClearable
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText={'Date'}
                        className="position-relative outline-none border-radius-10 border-0  w-100 pl-12px font-size-12 pr-64px"
                        calendarClassName=""
                    />
                    <ErrorMessage name="Date" component="label" className="error  text-danger"/>
                    </div>
                </div>
                <div className="form-group my-40px ">
                    <label className="" htmlFor="">
                            {props.langs.adminUploadImgOuter}
                    </label>
                    <UploadFormComponent {...props} {...{imgFieldName: 'OuterImg'}} />
                </div>
                <div className="form-group my-40px ">
                    <label className="" htmlFor="">
                            {props.langs.adminUploadImgInner}
                    </label>
                    <UploadFormComponent {...props} {...{imgFieldName: 'InnerImg'}} />
                </div>
                <div className="form-group">
                    <label className="" htmlFor="Video">
                        Video
                    </label>
                    <Field name="Video" className="form-control radius-10" />
                    <ErrorMessage name="Video" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <label>
                        <Field type="checkbox" name="Status" value="1" checked={parseInt(props.values.Status) === 1} onChange={() => {
                            props.setFieldValue("Status", parseInt(props.values.Status) === 1 ? 0 : 1)
                        }} />
                         <span className="px-8px">Status</span>
                        
                    </label>
                    <ErrorMessage name="Status" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <button type="submit" className={`btn btn-primary`} >
                       {props.langs.adminSave}
                    </button>
                </div>
            </Form>
        </div>
    )
}

export default CoursesFormComponent