import React, { useState, useEffect, useContext } from 'react'

import { API } from '../../../global'
import { HistoryContext } from 'contexts/history';
import { AppDataContext } from 'contexts/app';

import Details  from  './Details'
import List  from  './List'

const View = () => {
    const api = new API();

    const { params, stringify, push, assignParam }  =  useContext(HistoryContext)
    const { lang, getLang, langs }                  =  useContext(AppDataContext);

    const [data, setData]                           =  useState(false);
    const [paginate, setPaginate]                   = useState(null);
    const page                                      = 'courses'

    const fetchData = async () => {
        let apiData = await api.getCourses(
            api.routeParams.id ? api.routeParams.id : false, 
            !api.routeParams.id ? stringify({ Limit: 10, Page: params.Page ? params.Page : 1 }) : false
        );
        if (apiData.statusCode) {
            setData(apiData.data);
            if (!api.routeParams.id) {
                setPaginate({
                    pageCount: apiData.data.list.pagination.totalPages,
                    forcePage: params.Page !== null && params.Page > 0 ? params.Page - 1 : 0,
                    onPageChange: (data) => {
                        assignParam({ Page: data.selected + 1 }, true)
                    }
                });
            }
        } else {
            if (api.routeParams.id) {
                push(`/${getLang()}/${page}`)
            }
        }
    };

    const initial = () => {
        setData(false)
        fetchData()
    }

    useEffect(initial, [params]);
    
    return (
        <div className="container position-relative mt-24px mt-lg-48px">
            {(api.routeParams.id && data) ?
                <Details  {...{
                    data,
                    lang, 
                    getLang, 
                    langs,
                    page
                }}/>
            :
                <List  {...{
                    data,
                    routeID: false,
                    langs,
                    page,
                    paginate
                }}/>
            }
        </div>
    )
};

export default View;
