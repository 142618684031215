import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { Fb, Insta, YouTube } from '../../../global/components/icons';
import { AppDataContext } from 'contexts/app';

import {Registration} from '../components'

const View = () => {
    return (
        <></>
    )
};

export default View;
