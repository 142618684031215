import { useParams } from 'react-router-dom';
import { stringify } from 'query-string';
import axios from 'axios';
import CONFIG from './config';
import LocalStorage from './local'
class API {
	constructor () {
		this.storage 						= new LocalStorage();
		this.HTTPClient 				= axios;
		this.routeParams 				= useParams();
		this.lang 							= window.location.pathname.split('/')[1]
  	this.config							= CONFIG
		if (this.storage.exists(this.config.STORAGE.AUTH) && this.storage.get(this.config.STORAGE.AUTH).length > 11) {
			this.HTTPClient.defaults.headers.Authorization = `Bearer ${this.storage.get(this.config.STORAGE.AUTH)}`;
		}
	}
	buildURL = (method) => {
        return this.config.HOST.API_URL + method;
    }
	response = (res) => {
		let resData = res.data;
		let response = {
			data: resData.data ? resData.data : {},
			statusCode: parseInt(resData.statusCode) === 200 ? true : false,
			statusMessage: resData.statusMessage ? resData.statusMessage : ''
		}
		if (parseInt(resData.statusCode) === 401 || resData.statusMessage === 'Expired token') {
			this.storage.remove(this.config.STORAGE.AUTH);
			this.storage.remove(this.config.STORAGE.USER);
			window.location.href = `/${this.lang}/user/login`;
			return false;
		}
		return response;
	}
	uploadFiles = async (files) => {
		let data = new FormData();
		Object.keys(files).map(i => {
			return data.set(`Files[${i}]`, files[i]);
		});

		let res = await this.HTTPClient.post(this.buildURL(CONFIG.API.ADMIN.UPLOAD), data, {
			headers: {'Content-Type': 'multipart/form-data'}
		});
		return this.response(res);
	}

	getLangs = async (query) => {
		let res = await this.HTTPClient.get(this.buildURL(`${CONFIG.API.GET_LANGS}${query ? `?lang=${query}` : ''}`));
		return this.response(res);
	}

	getLangsList = async (id = false, query) => {
		let res = await this.HTTPClient.get(this.buildURL(`${CONFIG.API.ADMIN.GET_LANGS}${id ? `/${id}` : ''}${query ? `?${query}` : ''}`));
		return this.response(res);
	}

	updateLangs = async (id, data) => {
		let res = await this.HTTPClient.put(this.buildURL(`${CONFIG.API.ADMIN.EDIT_LANGS}/${id}`), data);
		return this.response(res);
	}

  getCourses = async (id = false, query) => {
		let res = await this.HTTPClient.get(this.buildURL(`${CONFIG.API.GET_COURSES}${id ? `/${id}` : ''}${query ? `?${query}` : ''}`));
		return this.response(res);
	}

	updateCourses = async (id, data) => {
		let res = await this.HTTPClient.put(this.buildURL(`${CONFIG.API.ADMIN.EDIT_COURSES}/${id}`), data);
		return this.response(res);
	}

	addCourses = async (data) => {
		let res = await this.HTTPClient.post(this.buildURL(CONFIG.API.ADMIN.ADD_COURSES), data);
		return this.response(res);
	}

	deleteCourses = async (id) => {
		let res = await this.HTTPClient.delete(this.buildURL(`${CONFIG.API.ADMIN.DELETE_COURSES}${`/${id}`}`));
		return this.response(res);
	}

	getContact = async () => {
		let res = await this.HTTPClient.get(this.buildURL(`${CONFIG.API.GET_CONTACT}`));
		return this.response(res);
	}

	getContactAdmin = async () => {
		let res = await this.HTTPClient.get(this.buildURL(`${CONFIG.API.ADMIN.GET_CONTACT}`));
		return this.response(res);
	}

	updateContact = async (data) => {
		let res = await this.HTTPClient.put(this.buildURL(`${CONFIG.API.ADMIN.EDIT_CONTACT}`), data);
		return this.response(res);
	}

	getAbout = async () => {
		let res = await this.HTTPClient.get(this.buildURL(`${CONFIG.API.GET_ABOUT}`));
		return this.response(res);
	}

	getAboutAdmin = async () => {
		let res = await this.HTTPClient.get(this.buildURL(`${CONFIG.API.ADMIN.GET_ABOUT}`));
		return this.response(res);
	}

	updateAbout = async (data) => {
		let res = await this.HTTPClient.put(this.buildURL(`${CONFIG.API.ADMIN.EDIT_ABOUT}`), data);
		return this.response(res);
	}

	getTerms = async () => {
		let res = await this.HTTPClient.get(this.buildURL(`${CONFIG.API.GET_TERMS}`));
		return this.response(res);
	}

	getTermsAdmin = async () => {
		let res = await this.HTTPClient.get(this.buildURL(`${CONFIG.API.ADMIN.GET_TERMS}`));
		return this.response(res);
	}

	updateTerms = async (data) => {
		let res = await this.HTTPClient.put(this.buildURL(`${CONFIG.API.ADMIN.EDIT_TERMS}`), data);
		return this.response(res);
	}

	getRegistration = async (id = false, query) => {
		let res = await this.HTTPClient.get(this.buildURL(`${CONFIG.API.ADMIN.GET_REGISTRATION}${id ? `/${id}` : ''}${query ? `?${query}` : ''}`));
		return this.response(res);
	}

	updateRegistration = async (id, data) => {
		let res = await this.HTTPClient.put(this.buildURL(`${CONFIG.API.ADMIN.EDIT_REGISTRATION}/${id}`), data);
		return this.response(res);
	}

	addRegistration = async (data) => {
		let res = await this.HTTPClient.post(this.buildURL(CONFIG.API.ADMIN.ADD_REGISTRATION), data);
		return this.response(res);
	}

	deleteRegistration = async (id) => {
		let res = await this.HTTPClient.delete(this.buildURL(`${CONFIG.API.ADMIN.DELETE_REGISTRATION}${`/${id}`}`));
		return this.response(res);
	}

	userLogin = async (data) => {
		let res = await this.HTTPClient.post(this.buildURL(CONFIG.API.USER.LOGIN), data);
		return this.response(res);
	}

	userRegister = async (data) => {
		let res = await this.HTTPClient.post(this.buildURL(CONFIG.API.USER.REGISTER), data);
		return this.response(res);
	}

	getProfile = async () => {
		let res = await this.HTTPClient.get(this.buildURL(`${CONFIG.API.USER.GET_PROFILE}`));
		return this.response(res);
	}

	updateProfile = async (data) => {
		let res = await this.HTTPClient.post(this.buildURL(`${CONFIG.API.USER.EDIT_PROFILE}`), data);
		return this.response(res);
	}

	getOrders = async (query) => {
		let res = await this.HTTPClient.get(this.buildURL(`${CONFIG.API.USER.GET_ORDERS}${query ? `?${query}` : ''}`));
		return this.response(res);
	}
	getAdminOrders = async (query) => {
		let res = await this.HTTPClient.get(this.buildURL(`${CONFIG.API.ADMIN.GET_ORDERS}${query ? `?${query}` : ''}`));
		return this.response(res);
	}
	getOrder = async (id) => {
		let res = await this.HTTPClient.get(this.buildURL(`${CONFIG.API.USER.GET_ORDERS}/${id}`));
		return this.response(res);
	}
	createOrder = async (data) => {
		let res = await this.HTTPClient.post(this.buildURL(`${CONFIG.API.USER.CREATE_ORDER}`), data);
		return this.response(res);
	}
	updateOrder = async (id, data) => {
		let res = await this.HTTPClient.put(this.buildURL(`${CONFIG.API.USER.EDIT_ORDER}/${id}`), data);
		return this.response(res);
	}
	checkOrder = async (id) => {
		let res = await this.HTTPClient.get(this.buildURL(`${CONFIG.API.USER.CHECK_ORDER}/${id}`));
		return this.response(res);
	}
	// Admin
	adminLogin = async (data) => {
		let res = await this.HTTPClient.post(this.buildURL(CONFIG.API.ADMIN.LOGIN), data);
		return this.response(res);
	}
	// Logout
	logout = async () => {
		let res = await this.HTTPClient.get(this.buildURL(CONFIG.API.USER.LOGOUT));
		return this.response(res);
	}
	
}
export default API
