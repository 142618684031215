import React, { useState, useEffect, useContext } from 'react'
import parse from 'html-react-parser';
import { API } from '../../../global'
import { AppDataContext } from 'contexts/app';

import { Loading } from '../../../global/components';
const View = () => {
    const api                                       = new API();
    const { lang }                                  =  useContext(AppDataContext);
    const [data, setData]                           =  useState(false);

    const fetchData = async () => {
        let apiData = await api.getTerms();
        if (apiData.statusCode) {
            setData(apiData.data);
        }
    };

    const initial = () => {
        fetchData()
    }

    useEffect(initial, []);
    return (
        <>
            <div className='container mt-24px'>
                <div className='text-white'>
                    {data ? parse(data[`text_${lang}`]) : ''}
                </div>
            </div>
        </>
    )
};

export default View;
