import React, {useState} from "react";
import * as Yup from 'yup';
import {Formik} from 'formik';
import Swal from 'sweetalert2'
import { RegistrationFormComponent } from "./";
const Registration = (props) => {
  const {api, getLang, langs, login, checkRedirectCheckOut} = props;
  const [submit, setSubmit]                   = useState(false)
  const config = {
    initialValues: {
      Name: '',
      Lastname: '',
      Email: '',
      Phone: '',
      Password: '',
      PasswordConfirm: '',
    },
    validationSchema: Yup.object().shape({
      Name: Yup.string().required('Name is Required'),
      Lastname: Yup.string().required('Lastname is Required'),
      Email: Yup.string().email('Invalid email').required('Email is Required'),
      Phone: Yup.string().required('Phone is Required'),
      Password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
      PasswordConfirm: Yup.string().required('Password Confirm is required').oneOf([Yup.ref('Password'), null], 'Passwords must match'),
    }),
    enableReinitialize: true
  }

  const handleSubmit = async (values) => {
    setSubmit(true)
    let apiData = await api.userRegister(values);
    if (apiData.statusCode && apiData.data.token) {
      let request = await login(apiData.data)
      if (request) {
        checkRedirectCheckOut()
      } else {
        Swal.fire({
          icon: 'error',
          title: langs.errorTitle,
          text: langs.errorText,
        })
        setTimeout(() => {
          setSubmit(false)
        }, 1000)
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: langs.errorTitle,
        text: langs.errorText,
      })
      setTimeout(() => {
        setSubmit(false)
      }, 1000)
    }
  }

  return (
    <div className="form-box p-16px p-md-36px border-radius-md col-12 col-md-6">
      <Formik {...config} onSubmit={handleSubmit} component={
        (formikProps) => <RegistrationFormComponent {...formikProps} {...{langs, getLang, submit}} />
      } />
    </div>
  )
}

export default Registration