import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'

import * as Yup from 'yup';
import { Formik } from 'formik';

import { Loading, Pagination } from '../../../global/components';
import { Sidebar, RegistrationFormComponent } from '../components'

const Registration = (props) => {
    const [list, setList] = useState(false);
    const [paginate, setPaginate]                                   = useState(null);

    const [single, setSingle] = useState({
        Email: '',
        Status: '1'
    });

    const config = {
        initialValues: single,
        validationSchema: Yup.object().shape({
            Email: Yup.string().email('Email format is expected').required('Email is required')
        }),
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
    }

    const handleSubmit = async (values) => {
        let apiData;
        if (props.api.routeParams.action === "edit" && props.api.routeParams.id) {
            apiData = await props.api.updateRegistration(props.api.routeParams.id, values)
        } else if (props.api.routeParams.action === "add") {
            apiData = await props.api.addRegistration(values)
        }
        props.onAction(apiData.statusCode, 'registration')
    }

    const uploadImages = async (files, form) => {
        let newList = []
        if (form.values[form.imgFieldName].indexOf(",") > -1) {
            newList = form.values[form.imgFieldName].split(',')
        } else if (form.values[form.imgFieldName].length > 0) {
            newList = [form.values[form.imgFieldName]]
        }
        const data = await props.api.uploadFiles(files)
        if (data) {
            form.setFieldValue(form.imgFieldName, newList.concat(data.data).join(','))
            return data
        }
        return false
    }

    const deleteRegistration = async (id) => {
        props.onDelete({
            isConfirmed: async () => await props.api.deleteRegistration(id),
            isSuccess: () => initial()
        })
    }

    const fetchData = async () => {
        let apiData = await props.api.getRegistration(
            props.api.routeParams.id ? props.api.routeParams.id : false,
            !props.api.routeParams.id ? props.stringify({ Admin: 1, Limit: 10, Page: props.params.Page ? props.params.Page : 1 }) : false
        );
        if (apiData.statusCode) {
            if (props.api.routeParams.id) {
                setSingle({
                    Email: apiData.data.email,
                    Status: apiData.data.status
                })
            } else {
                setList(apiData.data.data);
                setPaginate({
                    pageCount: apiData.data.pagination.totalPages,
                    forcePage: props.params.Page !== null && props.params.Page > 0 ? props.params.Page - 1 : 0,
                    onPageChange: (data) => {
                        props.assignParam({ Page: data.selected + 1 }, true)
                    }
                });
            }
        }
    };

    const initial = () => {
        if (props.api.routeParams.action !== "add") {
            fetchData()
        }
    }

    useEffect(initial, [props.params]);

    const showInfo = (item) => {
        Swal.fire({
            title: `ID${item.id}: ${item.name} ${item.lastname} ("${item.course}")`,
            html:`Name: ${item.name}<br>Lastname: ${item.lastname}<br>Phone: ${item.phone}<br>ID Number: ${item.idnumber}<br>Email: ${item.email}<br><br>Address: ${item.idnumber}`
        })
    }

    const ActiveComponent = (props) => {
        if (props.api.routeParams && ["add", "edit"].includes(props.api.routeParams.action)) {
            return <Formik {...config} onSubmit={handleSubmit} component={
                (formikProps) => <RegistrationFormComponent {...{
                    getLang: props.getLang,
                    langs: props.langs,
                    uploadImages
                }} {...formikProps} />
            } />
        } else if (list) {
            return (
                <div className="border border-blue border-radius-md p-12px">
                    <div className="row mb-12px">
                        <div className="col">ID</div>
                        <div className="col">Name</div>
                        <div className="col">Email</div>
                        <div className="col">Phone</div>
                        <div className="col">{props.langs.adminActions}</div>
                    </div>
                    {list.map((item, i) => (
                        <div key={i} className="row">
                            <div className="col">
                                {item.id}
                            </div>
                            <div className="col">
                                {item.name}
                            </div>
                            <div className="col">
                                {item.email}
                            </div>
                            <div className="col">
                                {item.phone}
                            </div>
                            <div className="col">
                                <div className="btn btn-primary mr-8px mb-8px font-size-18px" onClick={() => showInfo(item)} >
                                    {`View`}
                                </div>
                                <button className="btn btn-danger mr-8px mb-8px" onClick={() => deleteRegistration(item.id)}>
                                    {props.langs.adminDelete}
                                </button>
                            </div>
                        </div>
                    ))}
                    <div className="row d-flex justify-content-center mt-20px mt-sm-10px mt-lg-30px">
                        <Pagination {...paginate} />
                    </div>
                </div>
            )
        } else {
            return <Loading />
        }
    }

    return (
        <div className="row align-items-start">
            <Sidebar {...props} />
            <div className="col-m-9 admin-content">
                <ActiveComponent {...props} />
            </div>
        </div>
    )
};

export default Registration;
