import React from 'react'
import {Form, Field, ErrorMessage} from 'formik';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const ContactFormComponent = (props) => {
    const toolbarSettings = {
        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link'],
    }
    const changeEditorStateKa = (newState) => {
        props.setFieldValue('Text_ka', newState)
    }
    const changeEditorStateEn = (newState) => {
        props.setFieldValue('Text_en', newState)
    }
    return (
        <div className="container">
            <Form autoComplete="off">
                <div className="form-group">
                    <label className="" htmlFor="Title_ka">
                        {props.langs.adminTitle} {props.langs.localIn_ka}
                    </label>
                    <Field name="Title_ka" className="form-control radius-10" />
                    <ErrorMessage name="Title_ka" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <label className="" htmlFor="Title_en">
                        {props.langs.adminTitle} {props.langs.localIn_en}
                    </label>
                    <Field name="Title_en" className="form-control radius-10" />
                    <ErrorMessage name="Title_en" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <label className="" htmlFor="Text_ka">
                        {props.langs.adminText} {props.langs.localIn_ka}
                    </label>
                    <div className="border radius-10">
                        <Editor
                            editorState={props.values.Text_ka}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={changeEditorStateKa}
                            toolbar={toolbarSettings}
                        />
                    </div>
                    <ErrorMessage name="Text_ka" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <label className="" htmlFor="Text_en">
                        {props.langs.adminText} {props.langs.localIn_en}
                    </label>
                    <div className="border radius-10">
                        <Editor
                            editorState={props.values.Text_en}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={changeEditorStateEn}
                            toolbar={toolbarSettings}
                        />
                    </div>
                    <ErrorMessage name="Text_en" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <button type="submit" className={`btn btn-primary`} >
                      {props.langs.adminSave}
                    </button>
                </div>
            </Form>
        </div>
    )
}

export default ContactFormComponent