import React, {useState} from "react";
import * as Yup from 'yup';
import {Formik} from 'formik';
import { LoginFormComponent } from "./";
const Login = (props) => {
  const {api, getLang, langs, login, checkRedirectCheckOut} = props;
  const [error, setError]                   = useState(false)
  const config = {
    initialValues: {
      Email: '',
      Password: '',
    },
    validationSchema: Yup.object().shape({
      Email: Yup.string().email('Invalid email').required('Email is Required'),
      Password: Yup.string().required('Password is required'),
    }),
    enableReinitialize: true
  }

  const handleSubmit = async (values) => {
    let apiData = await api.userLogin(values);
    if (apiData.statusCode && apiData.data.token) {
      let request = await login(apiData.data)
      if (request) {
        checkRedirectCheckOut()
      } else {
        setError(true)
      }
    } else {
      setError(true)
    }
  }

  return (
    <div className="form-box p-16px p-md-36px border-radius-md col-12 col-md-6" >
      <Formik {...config} onSubmit={handleSubmit} component={
        (formikProps) => <LoginFormComponent {...formikProps} {...{langs, getLang, error}} />
      } />
    </div>
  )
}

export default Login