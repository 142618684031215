import React from 'react';
import {Helmet} from "react-helmet-async";


// import { HistoryContext } from '../../contexts/history';

const Meta = (props) => {
    // const { history, getPagename }                  = useContext(HistoryContext)
    // const metaConfig                                = getConfig({langs})
    const getDefaults = () => {
        return {
            title: '',
            description: '',
            keywords: '',
        }
    }
    
    // const [page, setPage]                        = useState(null)
    const meta                        = getDefaults()

    // const processPage = () => {
    //     let newPage = getPagename()
    //     if (page !== newPage) {
    //         setPage(newPage)
    //     }
    // }

    // const processMeta = () => {
    //     if (page) {
    //         let newConfig = metaConfig[page]
    //         if (newConfig) {
    //             let newMeta = {
    //                 title: `${CONFIG.COMMON.SITE}${newConfig.title ? ` - ${newConfig.title}` : ''}`,
    //                 description: newConfig.description ? newConfig.description : langs.MetaDescr,
    //                 keywords: newConfig.keywords ? newConfig.keywords : langs.MetaKeywords,
    //             }
    //             setMeta(newMeta)
    //         }
    //     }
    // }

    // useEffect(processMeta, [page])

    // const initial = () => {
    //     processPage()
    // }

    // useEffect(initial, [history.location])

    return (
        <Helmet htmlAttributes={{lang: props.lang}}>
            <title>{meta.title}</title>
            <meta name="description" lang={props.lang} content={meta.description} />
            <meta name="keywords" lang={props.lang} content={meta.keywords} />
        </Helmet>
    )
}

export default  Meta