import React, { useState, useEffect, useContext } from 'react'
import parse from 'html-react-parser';
import { API } from '../../../global'
import { AppDataContext } from 'contexts/app';

import { Loading } from '../../../global/components';
import { Header } from '../components'

const View = () => {
    const api                                       = new API();
    const { langs, lang }                           =  useContext(AppDataContext);
    const [data, setData]                           =  useState(false);

    const fetchData = async () => {
        let apiData = await api.getAbout();
        if (apiData.statusCode) {
            setData(apiData.data);
        }
    };

    const initial = () => {
        fetchData()
    }

    useEffect(initial, []);
    return (
        <div className="container position-relative mt-24px mt-lg-48px">
            <Header {...{langs}} />
            {data ? 
                <>
                    <div className="row">
                        <div className="col-md-6 single-news-text-box  my-16px ">
                            <div className="single-news-text text-white font-size-18 font-base mt-8px">
                                {parse(data[`left_text_${lang}`])}
                            </div>
                        </div>
                        <div className="col-md-6 single-news-media-box ">
                            <img src={data.left_image} alt=""  className='w-100 object-fit-cover'/>
                        </div>
                    </div>
                    <div className="row flex-column-reverse flex-md-row">
                        <div className="col-md-6 single-news-media-box my-16px ">
                            <img src={data.right_image} alt=""   className='w-100 object-fit-cover'/>
                        </div>
                        <div className="col-md-6 single-news-text-box">
                            <div className="single-news-text text-white font-size-18 font-base mt-8px">
                                {parse(data[`right_text_${lang}`])}
                            </div>
                        </div>
                    </div>
                </>
            :
                <Loading />
            }
        </div>
    )
};

export default View;
