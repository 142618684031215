class LocalStorage {
    
    constructor() {
        this.storage = localStorage;
    }

    exists(key) {
        return this.get(key) !== null;
    }
    
    length() {
        return this.storage.length;
    }

    get(key) {
        return this.storage.getItem(key);
    }

    getJson(key) {
        return JSON.parse(this.storage.getItem(key));
    }

    set(key, val) {
        return this.storage.setItem(key, val);
    }
    
    remove(key) {
        return this.storage.removeItem(key);
    }

    clear() {
        return this.storage.clear();
    }
}

export default LocalStorage;