import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import * as Yup from 'yup';
import { Formik } from 'formik';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { Loading, Pagination } from '../../../global/components';
import { Sidebar, CoursesFormComponent } from '../components'

const Courses = (props) => {
    const [list, setList] = useState(false);
    const [paginate, setPaginate]                                   = useState(null);

    const [single, setSingle] = useState({
        GroupID: '',
        Price: '',
        Title_ka: '',
        Title_en: '',
        ShortDescription_ka: '',
        ShortDescription_en: '',
        Description_ka: EditorState.createEmpty(),
        Description_en: EditorState.createEmpty(),
        Date: '',
        OuterImg: '',
        InnerImg: '',
        Video: '',
        Archive: '0',
        Status: '1'
    });

    const config = {
        initialValues: single,
        validationSchema: Yup.object().shape({
            GroupID: Yup.string().required('Group is required'),
            Price: Yup.string().required('Price is required'),
            Title_ka: Yup.string().required('Title is required'),
            ShortDescription_ka: Yup.string().required('ShortDescription is required'),
            Date: Yup.string().required('Date is required'),
            OuterImg: Yup.string().required('OuterImg is required'),
            InnerImg: Yup.string().required('InnerImg is required')
        }),
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
    }

    const processEditorState = (data) => {
        const blocksFromHtml = htmlToDraft(data)
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        return contentState
    }

    const handleSubmit = async (values) => {
        let apiData;
        let finalData = {...values}
        finalData.Description_ka = draftToHtml(convertToRaw(finalData.Description_ka.getCurrentContent()))
        finalData.Description_en = draftToHtml(convertToRaw(finalData.Description_en.getCurrentContent()))
        if (props.api.routeParams.action === "edit" && props.api.routeParams.id) {
            apiData = await props.api.updateCourses(props.api.routeParams.id, finalData)
        } else if (props.api.routeParams.action === "add") {
            apiData = await props.api.addCourses(finalData)
        }
        props.onAction(apiData.statusCode, 'courses')
    }

    const uploadContentImage = async (files) => {
        const data = await props.api.uploadFiles(files)
        if (data.statusCode) {
            return data.data[0]
        }
        return false
    }

    const uploadImages = async (files, form) => {
        let newList = []
        if (form.values[form.imgFieldName].indexOf(",") > -1) {
            newList = form.values[form.imgFieldName].split(',')
        } else if (form.values[form.imgFieldName].length > 0) {
            newList = [form.values[form.imgFieldName]]
        }
        // console.log(newList)
        const data = await props.api.uploadFiles(files)
        if (data) {
            form.setFieldValue(form.imgFieldName, newList.concat(data.data).join(','))
            return data
        }
        return false
    }

    const deleteCourses = async (id) => {
        props.onDelete({
            isConfirmed: async () => await props.api.deleteCourses(id),
            isSuccess: () => initial()
        })
    }

    const fetchData = async () => {
        let apiData = await props.api.getCourses(
            props.api.routeParams.id ? props.api.routeParams.id : false,
            !props.api.routeParams.id ? props.stringify({ Admin: 1, Limit: 10, Page: props.params.Page ? props.params.Page : 1 }) : false
        );
        if (apiData.statusCode) {
            if (props.api.routeParams.id) {
                setSingle({
                    GroupID: apiData.data.group_id,
                    Price: apiData.data.price,
                    Title_ka: apiData.data.title_ka,
                    Title_en: apiData.data.title_en,
                    ShortDescription_ka: apiData.data.short_description_ka,
                    ShortDescription_en: apiData.data.short_description_en,
                    Description_ka: EditorState.createWithContent(processEditorState(apiData.data.description_ka)),
                    Description_en: EditorState.createWithContent(processEditorState(apiData.data.description_en)),
                    Date: apiData.data.date,
                    OuterImg: apiData.data.outer_img,
                    InnerImg: apiData.data.inner_img,
                    Video: apiData.data.video,
                    Status: apiData.data.status
                })
            } else {
                setList(apiData.data.list.data);
                setPaginate({
                    pageCount: apiData.data.list.pagination.totalPages,
                    forcePage: props.params.Page !== null && props.params.Page > 0 ? props.params.Page - 1 : 0,
                    onPageChange: (data) => {
                        props.assignParam({ Page: data.selected + 1 }, true)
                    }
                });
            }
        }
    };

    const initial = () => {
        if (props.api.routeParams.action !== "add") {
            fetchData()
        }
    }

    useEffect(initial, [props.params]);

    const ActiveComponent = (props) => {
        if (props.api.routeParams && ["add", "edit"].includes(props.api.routeParams.action)) {
            return <Formik {...config} onSubmit={handleSubmit} component={
                (formikProps) => <CoursesFormComponent {...{
                    getLang: props.getLang,
                    langs: props.langs,
                    uploadImages,
                    uploadContentImage
                }} {...formikProps} />
            } />
        } else if (list) {
            return (
                <div className="border border-blue border-radius-md p-12px">
                    <div className="row mb-12px">
                        <div className="col">ID</div>
                        <div className="col">{props.langs.adminTitle}</div>
                        <div className="col">{props.langs.adminActions}</div>
                    </div>
                    {list.map((item, i) => (
                        <div key={i} className="row">
                            <div className="col">
                                {item.id}
                            </div>
                            <div className="col">
                                {item.title_ka}
                            </div>
                            <div className="col">
                                <Link to={`/${props.getLang()}/admin/courses/edit/${item.id}`} className="btn btn-primary mr-8px mb-8px font-size-18px" >
                                 {props.langs.adminEdit}
                                </Link>
                                <button className="btn btn-danger mr-8px mb-8px" onClick={() => deleteCourses(item.id)}>
                                  {props.langs.adminDelete}
                                </button>
                            </div>
                        </div>
                    ))}
                    <div className="row d-flex justify-content-center mt-20px mt-sm-10px mt-lg-30px">
                        <Pagination {...paginate} />
                    </div>
                </div>
                
            )
        } else {
            return <Loading />
        }
    }

    return (
        <div className="row align-items-start">
            <Sidebar {...props} />
            <div className="col-m-9 admin-content">
                <div className="container">
                    <Link to={`/${props.getLang()}/admin/courses/add`} className="btn bg-primary text-white font-size-18 mb-24px px">
                     {props.langs.adminAddCourses}
                    </Link>
                </div>
                <ActiveComponent {...props} />
            </div>
        </div>
    )
};

export default Courses;
