import React, { useState, useEffect, createContext } from 'react';

import { API } from '../global'
import CONFIG from '../global/services/config'
export const AppDataContext = createContext();

export const AppDataProvider = (props) => {
	const api 									= new API();
	const [lang, setLang]						= useState(window.location.pathname.split('/')[1])
	const [langs, setLangs] 					= useState(false);

	const getLang = () => {
		return lang
	};

	const getData = async () => {
		let apiData = await api.getLangs(lang)
		if (apiData.statusCode) {
			setLangs(apiData.data)
		} else {
			setLangs([])
		}
	};

	const initial = () => {
		if (!langs) {
			getData()
		}
	}

	useEffect(initial, [lang]);

	const changeLanguage = (newLang) => {
		api.storage.set(CONFIG.STORAGE.LANGUAGE, newLang)
		setLangs(false)
		setLang(newLang)
	}

	return (
		<AppDataContext.Provider value={{lang, langs, getLang, changeLanguage}}>
			{props.children}
		</AppDataContext.Provider>
	);
};
