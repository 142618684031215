import React, { useState, useContext, useEffect } from 'react';

import { API } from '../../../global'
import { AppDataContext } from 'contexts/app';
import { HistoryContext } from 'contexts/history';
import { UserContext } from 'contexts/user';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';

const Registration = () => {
	const api = new API();
	const { params, stringify } = useContext(HistoryContext)
	const { langs, lang } = useContext(AppDataContext);
	const { checkIfAuthorized, getUserData } = useContext(UserContext);
	const [course, setCourse] = useState(false);

	let user = getUserData();

	const preRegister = async () => {
		const sendData = await api.addRegistration({
			Name: user.name,
			Lastname: user.lastname,
			Email: user.email,
			Phone: user.phone,
			// IDNumber: '',
			// Address: '',
			Package: params.package,
			Terms: '',
			Status: 1,
		});
		Swal.fire({
			icon: !sendData.statusCode ? 'error' : 'success',
			title: !sendData.statusCode ? langs.errorTitle : langs.successTitle,
			text: !sendData.statusCode ? langs.errorText : langs.successText,
		})
	}
	// const buyCourse = async () => {
	// 	const createOrder = await api.createOrder({
	// 		CourseID: params.package,
	// 	});
	// 	if (createOrder.statusCode) {
	// 		window.location.href = createOrder.data.href;
	// 	} else {
	// 		Swal.fire({
	// 			icon: 'error',
	// 			title: langs.errorTitle,
	// 			text: langs.errorText,
	// 		})
	// 	}
	// }

	const initial = () => {
		setCourse(false)
		const fetchData = async () => {
			let apiData = await api.getCourses(params.package);
			if (apiData.statusCode) {
				setCourse(apiData.data);
			}
		};
		if (params.package && !course) {
			fetchData();
		}
	}

	useEffect(initial, [params]);

	return (
		<div className="container pt-24px pb-100px mt-64px">
			<div className="form-box p-16px p-md-36px w-100 max-width-750px border-radius-md">
				<h2 className="font-size-24 font-size-md-28 font-bold-caps text-center mb-12px text-main-title pb-12px border-bottom">
					{langs.localRegister}
				</h2>
				{course &&
					<div className="border-bottom pb-12px mb-16px">
						<h2 className="font-size-24 font-bold-caps text-left mb-12px text-main-title">
							ინფორმაცია კურსზე
						</h2>
						<div className='font-size-18 font-base text-white'>
							{course[`title_${lang}`]}
						</div>
						<div className='font-size-18 font-base text-white'>
							{course[`short_description_${lang}`]}
						</div>
						<div className='font-size-18 font-base text-white'>
							{course.price} GEL
						</div>
					</div>
				}
				{checkIfAuthorized() ?
					<div className="text-cente">
						<div className="pb-24px mb-16px border-bottom">
							<h2 className="font-size-24 font-bold-caps text-left mb-12px text-main-title">
								მომხმარებლის ინფორმაცია
							</h2>
							<div className=" pb-12px mb-16px d-flex flex-column align-items-start">
								<div className='font-size-24 font-base text-white'>
									{user.name} {user.lastname}
								</div>
								<div className='font-size-21 font-base text-white'>
									{user.email}
								</div>
								<div className='font-size-21 font-base text-white'>
									{user.phone}
								</div>
							</div>
						</div>
						<div className="row justify-content-center mt-24px">
							<button className='font-size-18 btn bg-main-title text-white border-radius-md font-base-caps mx-auto'
								onClick={preRegister}>
								{langs.localPreRegister}
							</button>
							{/* <button className='font-size-18 btn bg-main-title text-white border-radius-md font-base-caps mx-auto'
								onClick={buyCourse}>
								{langs.localBuyCourse}
							</button> */}
						</div>
					</div>
					:
					<div className="pb-12px mb-16px">
						<div className="row justify-content-center  mt-16px">
							<Link to={`/${lang}/user/login?${stringify()}`} className='font-size-18 btn bg-main-title text-white border-radius-md font-base-caps mx-auto'>
								{langs.localAuthorization}
							</Link>
							<Link to={`/${lang}/user/registration?${stringify()}`} className='font-size-18 btn bg-main-title text-white border-radius-md font-base-caps mx-auto'>
								{langs.localRegistration}
							</Link>
						</div>
					</div>
				}
			</div>
		</div>
	)
};

export default Registration;