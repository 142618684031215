import React from 'react'
import {Link} from 'react-router-dom'
const Sidebar = (props) => {
    return (
        <div className="col-m-3 mb-24px mb-m-0 bg-white border-radius-md admin-sedebar p-24px">
            <ul>
                <li>
                    <Link to={`/${props.getLang()}/admin/home`}  className="text-black font-size-21 my-8px d-block">
                        {props.langs.adminDashboard}
                    </Link>
                </li>
                <li>
                    <Link to={`/${props.getLang()}/admin/about`}  className="text-black font-size-21 my-8px d-block">
                        {props.langs.adminAboutPage}
                    </Link>
                </li>
                <li>
                    <Link to={`/${props.getLang()}/admin/contact`}  className="text-black font-size-21 my-8px d-block">
                     {props.langs.adminContactPage}
                    </Link>
                </li>
                <li>
                    <Link to={`/${props.getLang()}/admin/terms`}  className="text-black font-size-21 my-8px d-block">
                     {props.langs.adminTermsPage}
                    </Link>
                </li>
                <li>
                    <Link to={`/${props.getLang()}/admin/courses`}  className="text-black font-size-21 my-8px d-block">
                         {props.langs.adminCourses}
                    </Link>
                </li>
                <li>
                    <Link to={`/${props.getLang()}/admin/registration`}  className="text-black font-size-21 my-8px d-block">
                        {props.langs.adminRegistration}
                    </Link>
                </li>
                <li>
                    <Link to={`/${props.getLang()}/admin/orders`}  className="text-black font-size-21 my-8px d-block">
                        {props.langs.localOrders}
                    </Link>
                </li>
                <li>
                    <Link to={`/${props.getLang()}/admin/langs`}  className="text-black font-size-21 my-8px d-block">
                        {props.langs.adminLangs}
                    </Link>
                </li>
                <li className="mt-56px">
                    <button className="text-black font-size-18 my-8px d-block btn bg-black text-white" onClick={props.logout}>
                        {props.langs.adminLogOut}
                    </button>
                </li>
            </ul>
        </div>
    )
}

export default Sidebar