import React from 'react'
import { Link } from 'react-router-dom';
import {Form, Field, ErrorMessage} from 'formik';

const LoginFormComponent = (props) => {
    const {error, langs, getLang} = props
    return (
        <div>
            <Form autoComplete="off">
                <div className="form-group">
                    <label className="" htmlFor="Email">
                        {langs.localEmail}
                    </label>
                    <Field name="Email" className="form-control radius-10" />
                    <ErrorMessage name="Email" component="label" className="error text-danger pl-25px" />
                </div>
                <div className="form-group">
                    <label className="" htmlFor="Password">
                        {langs.localPassword}
                    </label>
                    <Field type="password" name="Password" className="form-control radius-10" />
                    <ErrorMessage name="Password" component="label" className="error  text-danger pl-25px" />
                </div>
                <div className="form-group text-center">
                    <button type="submit" className={`btn btn-primary`} >
                        {langs.localLogin}
                    </button>
                </div>
                {error && 
                    <div className="">
                        Login Error
                    </div>
                }
            </Form>
            <div className="mt-24px font-size-18 text-white text-center font-base-caps">
                <Link to={`/${getLang()}/user/registration`} className="text-white">
                    {langs.localRegistration}
                </Link>
            </div>
        </div>
    )
}

export default LoginFormComponent